import React, { Component, useState, useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { InvoiceListType, Permissions, StatementMenu } from '../../../constants'
import { statementPaceService } from '../../../services'
import { auth, common, formatter, validator } from '../../../util'

// UI
import { List, Page, Pager } from '../../../components'

import Icon from 'antd/lib/icon'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'

import './styles.css'

const pageSize = 10

function getTitle (type) {
  let title

  if (type === InvoiceListType.INV_LIST_PM) {
    title = 'PM SB Statements'
  } else if (type === InvoiceListType.INV_LIST_STD) {
    title = 'Std SB Statements'
  } else {
    title = 'Statement Periods'
  }

  return title
}

function getStatementColumns (filterType) {
  const columns = [
    {
      title: 'Period',
      width: 3,
      render: ({ name_full: nameFull }) => nameFull
    },
    {
      title: 'Total Participants',
      width: 3,
      render: ({ participant }) => participant
    },
    {
      title: 'Total Invoiced Amount',
      width: 3,
      render: ({ invoiced_amount: invoicedAmount }) => formatter.toPrice(invoicedAmount)
    },
    {
      title: 'Total Paid Amount',
      width: 3,
      render: ({ paid_amount: paidAmount }) => formatter.toPrice(paidAmount)
    }
  ]

  if (filterType === InvoiceListType.INV_LIST_PM || filterType === InvoiceListType.INV_LIST_STD) {
    if (filterType === InvoiceListType.INV_LIST_PM) {
      columns.push({
        title: 'Total Credit Used',
        width: 3,
        render: ({ credit_used: creditUsed }) => formatter.toPrice(creditUsed)
      })
    }

    columns.push({
      title: '',
      width: 1,
      render: ({ period_month: periodMonth }) => (
        <div className='action-buttons'>
          <Link to={`/statement-pace/period/${filterType}/${periodMonth}`} rel='noopener noreferrer' target='_blank' >
            <Icon type='eye' />
          </Link>
        </div>
      )
    })
  }

  return columns
}

function StatementList (props) {
  const { match } = props || {}
  const [init, setInit] = useState(true)
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const { type = InvoiceListType.INV_LIST_PM } = common.getPath(match)
  const title = getTitle(type)

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  const listByPage = useCallback(({ page }) => {
    const _page = typeof page === 'number' && page > 0 ? page : 1
    setLoading(true)
    setPage(_page)
    statementPaceService.listStatementPeriodByPage(_page, pageSize, {}, type)
      .then((response) => {
        if (validator.isObject(response)) {
          const { list, total } = response

          if (Array.isArray(list)) {
            setList(list)
          }

          if (typeof total === 'number') {
            setTotal(total)
          }
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [type])

  const changePage = useCallback((page) => {
    listByPage({ page })
  }, [listByPage])

  useEffect(() => {
    if (!hasAccess(Permissions.STATEMENT.INFO_PACE.READ)) {
      setInit(false)
      return
    }

    let mounted = true
    setLoading(true)
    statementPaceService.listStatementPeriodByPage(1, pageSize, {}, type)
      .then((response) => {
        if (mounted && validator.isObject(response)) {
          const { list, total } = response

          if (Array.isArray(list)) {
            setList(list)
          }

          if (typeof total === 'number') {
            setTotal(total)
          }
        }
      })
      .finally(() => {
        if (mounted) {
          setInit(false)
          setLoading(false)
        }
      })

    return () => {
      mounted = false
    }
  }, [hasAccess, type])

  return (
    <Page.Body>
      <Page.Left>
        <Page.Menu title='Home' menu={StatementMenu} />
      </Page.Left>
      <Page.Content full>
        <Page.Header title={title} />

        <Skeleton active loading={init}>
          <Spin spinning={loading}>
            <List cols={getStatementColumns(type)} rows={list} />
          </Spin>
        </Skeleton>

        <Pager
          size={pageSize}
          total={total}
          totalText={`Total ${total} periods`}
          current={page}
          onChange={changePage}
          style={{ marginTop: '15px' }}
        />
      </Page.Content>
    </Page.Body>
  )
}

export class StatementListPeriod extends Component {
  render () {
    return <StatementList {...this.props} />
  }
}

export default StatementListPeriod