import React, { Component, Fragment, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import clsx from 'clsx'
import debounce from 'lodash.debounce'
import moment from 'moment-timezone'

import { apiHostname } from '../../../config'
import {
  ExportType, FileUploadMsg, InvoiceListType, InvoicePaceMenu, InvoiceStatus, InvoiceTypePace as InvoiceType, InvoiceUpdateType,
  Permissions, ReportRequestType
} from '../../../constants'
import {
  clientService, invoiceBulkPaceService, invoiceListService, invoicePaceService, providerService, reportSchedulerPaceService
} from '../../../services'
import { auth, exportFilePace, formatter, validator } from '../../../util'

// UI
import Checkbox from 'antd/lib/checkbox'
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Popover from 'antd/lib/popover'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Switch from 'antd/lib/switch'
import Tooltip from 'antd/lib/tooltip'
import Upload from 'antd/lib/upload'

import { Button, ControlLabel, Page, Pager, Panel, SearchInput, List } from '../../../components'
import notify from '../../../components/Notification'

import './styles.css'

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const { RangePicker } = DatePicker
const { Item: FormItem } = Form
const { TextArea } = Input
const { confirm } = Modal
const { Option } = Select

const dateFormat = 'DD/MM/YYYY'
const defaultPageSize = 20
const defaultDateRangeValidation = Object.freeze({ message: 'Please select date range to export', status: 'error' })
const defaultCheckboxValidation = Object.freeze({ message: 'Please select at least one item to export', status: 'error' })
const urlRedirect = '/invoices-pace'
const messageInvalidBulkUpdateAction = 'Invalid bulk update action or no item selected.'
const messageAtLeastOneInvoice = 'Please select at least one invoice.'
const onChangePromptInfo = {
  title: 'There are unsaved changes in this page',
  content: <div>
    <p>If you navigate to another page, all unsaved values in this page will be cleared.</p>
    <p style={{ color: 'red', fontWeight: 'bold' }}>ARE YOU SURE?</p>
  </div>
}

const DefaultStatus = Object.freeze(InvoiceStatus.All)
const SortingAll = Object.freeze({ processed_at: -1, invoice_date: -1, client_name: 1, id: 1 })
const SortingOthers = Object.freeze({ processed_at: 1, invoice_date: 1, client_name: 1, id: 1 })
const SortingToReceive = Object.freeze({ id: 1, processed_at: 1, invoice_date: 1, client_name: 1 })

// function InvAuthoriseButton ({ hasAccess, item }) {
//   const { status_reference: statusRef } = item
//   const isAuthorised = statusRef >= '005'

//   return typeof hasAccess === 'function' && hasAccess(Permissions.INVOICE.INFO_PACE.UPDATE) ? (
//     !isAuthorised
//       ? (
//         <Tooltip mouseLeaveDelay={0} title='Authorise Invoice'>
//           <div
//             style={{ cursor: 'pointer' }}
//           // onClick={() => this.triggerStatusUpdateModal(true, InvoiceUpdateType.INV_UPDATE_AUTHORISED, item)}
//           >
//             <Icon type='check-circle' style={{ color: '#aaa', marginTop: '2px', marginRight: '10px', fontSize: '15px' }} />
//           </div>
//         </Tooltip>
//       )
//       : <Icon type='check-circle' theme='filled' style={{ color: '#2ec77a', marginTop: '2px', marginRight: '10px' }} />
//   ) : null
// }

function InvCheckbox ({ hasAccess, idx, item, onChange }) {
  const isChecked = item.checked

  const changeChecked = useCallback(({ target }) => {
    const { checked } = target

    if (typeof onChange === 'function') {
      item.checked = checked
      onChange(idx, item)
    }
  }, [onChange, idx, item])

  return typeof hasAccess === 'function' || hasAccess(Permissions.INVOICE.INFO_PACE.UPDATE)
    ? (
      <div style={{ color: '#D66E00' }}>
        <Checkbox checked={isChecked} onChange={changeChecked} />
      </div>
    )
    : null
}

function InvRejectButton ({ hasAccess, item, onClick }) {
  const { status_reference: statusRef } = item
  const isRejected = statusRef === '010'

  const clickReject = useCallback((event) => {
    if (typeof onClick === 'function') {
      onClick(InvoiceUpdateType.INV_UPDATE_REJECTED, item)(event)
    }
  }, [item, onClick])

  return typeof hasAccess === 'function' || hasAccess(Permissions.INVOICE.INFO_PACE.UPDATE) ? (
    !isRejected
      ? (
        <div style={{ cursor: 'pointer' }} onClick={clickReject}>
          <Tooltip mouseLeaveDelay={0} title='Reject Invoice'>
            <Icon type='close-circle' style={{ color: '#aaa' }} />
          </Tooltip>
        </div >
      )
      : <Icon type='close-circle' theme='filled' style={{ color: '#cc0000' }} />
  ) : null
}

function InvViewButton ({ hasAccess, item }) {
  const { ref_id: refId } = item

  return typeof hasAccess === 'function' && hasAccess(Permissions.INVOICE.INFO_PACE.READ)
    ? (
      <Link to={`${urlRedirect}/${refId}/info`}>
        <Tooltip mouseLeaveDelay={0} title='Manage invoice'>
          <Icon type='form' style={{ color: '#d66e00' }} />
        </Tooltip>
      </Link>
    )
    : null
}

function getInvoiceBulkUpdateModalData (action, props, list) {
  const { form } = props || {}
  const { getFieldDecorator, getFieldValue } = form
  const isArray = Array.isArray(list)
  const isSingle = isArray && list.length === 1
  let content
  let title

  const columnsPayment = [
    {
      title: 'JID',
      width: 3,
      render: ({ jid_number, ref_id }) => (
        <Link to={`/invoices-pace/${ref_id}/info`} rel='noopener noreferrer' target='_blank'>
          {formatter.capitalize(jid_number, false)}
        </Link>
      )
    },
    {
      title: 'Processed',
      width: 2,
      render: ({ processed_at }) => formatter.toShortDate(processed_at)
    },
    {
      title: 'Inv Date',
      width: 2,
      render: ({ invoice_date }) => formatter.toShortDate(invoice_date)
    },
    {
      title: 'Inv No.',
      width: 2,
      render: ({ invoice_number }) => <div>{invoice_number}</div>
    },
    {
      title: 'Participant',
      width: 3,
      render: ({ client_name }) => <div>{client_name}</div>
    },
    {
      title: 'Provider',
      width: 3,
      render: ({ provider_id, provider_name }) => provider_id
        ? <div>{provider_name}</div>
        : provider_name
          ? <div style={{ fontStyle: 'italic' }}>{provider_name}</div>
          : <div>N/A</div>
    },
    {
      title: 'Inv Total',
      width: 4,
      render: ({ amount }) => <div>{formatter.toPrice(amount)}</div>
    },
    {
      title: 'PMYT Total',
      width: 5,
      render: (item) => {
        return <div>{formatter.toPrice(item.received_amount)}</div>
      }
    }
  ]

  if (action === InvoiceUpdateType.INV_UPDATE_ABA_RMT) {
    const toPayAmount = isArray
      ? list.reduce((prev, curr) => formatter.toBigNumber(prev).plus(curr.received_amount).toFixed(2), 0)
      : 0
    content = (
      <div>
        <div className='ip-text-error'><b>To Pay Amount: {formatter.toPrice(toPayAmount)}</b></div>
        <FormItem label='Select Payment Date for ABA'>
          {getFieldDecorator('payment_date', {
            initialValue: moment(),
            rules: [
              { required: true, message: 'Please select payment date' }
            ]
          })(
            <DatePicker disabledDate={formatter.disablePreviousDates} format={dateFormat} />
          )}
        </FormItem>
        <div className='title'>Do you want to generate ABA / REMIT for the following invoice{isSingle ? '' : 's'}?</div>
        <List cols={columnsPayment} rows={list} />
      </div>
    )
    title = 'Generate ABA / REMIT?'
  } else if (action === InvoiceUpdateType.INV_UPDATE_AUTHORISED) {
    content = (
      <div>
        <div className='title'>Do you want to authorise the following invoice{isSingle ? '' : 's'}?</div>
        <ul className='list'>
          {isArray ? list.map(({
            client_name: clientName, provider_name: providerName, invoice_number: invoiceNumber,
            invoice_provider_name: invoiceProviderName, amount, processed_at: processAt
          }, idx) => (
            <li key={idx} className='title'>
              <div>{invoiceNumber} ({formatter.toPrice(amount)}) - processed at {formatter.toShortDate(processAt)}</div>
              <div>for <b>{clientName}</b></div>
              {providerName || invoiceProviderName ? <div>with <i>{providerName || invoiceProviderName}</i></div> : null}
            </li>
          )) : null}
        </ul>
      </div>
    )
    title = `Authorise Invoice${isSingle ? '' : 's'}?`
  } else if (action === InvoiceUpdateType.INV_UPDATE_DELETE) {
    content = (
      <div>
        <div className='title'>Do you want to delete the following invoice{isSingle ? '' : 's'}?</div>
        <div><b style={{ color: '#ff0000' }}>This action is IRREVERSIBLE!</b></div>
        <ul className='list'>
          {isArray ? list.map(({
            client_name: clientName, provider_name: providerName, invoice_number: invoiceNumber,
            invoice_provider_name: invoiceProviderName, amount, processed_at: processAt
          }, idx) => (
            <li key={idx} className='title'>
              <div>{invoiceNumber} ({formatter.toPrice(amount)}) - processed at {formatter.toShortDate(processAt)}</div>
              <div>for <b>{clientName}</b></div>
              {providerName || invoiceProviderName ? <div>with <i>{providerName || invoiceProviderName}</i></div> : null}
            </li>
          )) : null}
        </ul>
      </div>
    )
    title = `Delete Invoice${isSingle ? '' : 's'}?`
  } else if (action === InvoiceUpdateType.INV_UPDATE_PYMT_REQUEST) {
    content = (
      <div>
        <div className='title'>Do you want to generate payment request for the following invoice{isSingle ? '' : 's'}?</div>
        <ul className='list'>
          {isArray ? list.map(({
            client_name: clientName, provider_name: providerName, invoice_number: invoiceNumber,
            invoice_provider_name: invoiceProviderName, amount, processed_at: processAt
          }, idx) => (
            <li className='title' key={idx}>
              <div>{invoiceNumber} ({formatter.toPrice(amount)}) - processed at {formatter.toShortDate(processAt)}</div>
              <div>for <b>{clientName}</b></div>
              {providerName || invoiceProviderName ? <div>with <i>{providerName || invoiceProviderName}</i></div> : null}
            </li>
          )) : null}
        </ul>
      </div>
    )
    title = 'Generate Payment Request?'
  } else if (action === InvoiceUpdateType.INV_UPDATE_RCV_PYMT) {
    content = (
      <div>
        <div className='title'>Do you want to update payment for the following invoice{isSingle ? '' : 's'}?</div>
        <ul className='list'>
          {isArray ? list.map((item, idx) => {
            const {
              client_name: clientName, provider_name: providerName, invoice_number: invoiceNumber,
              invoice_provider_name: invoiceProviderName, amount, status_date: statusDate, checked, items
            } = item
            return checked === true ? (
              <li key={idx} className='title'>
                <div>
                  {invoiceNumber} ({formatter.toPrice(amount)}) - claimed at {formatter.toShortDate(statusDate)} for <b>{clientName}</b>
                </div>
                {providerName || invoiceProviderName ? <div>with <i>{providerName || invoiceProviderName}</i></div> : null}

                {Array.isArray(items) ? (
                  <ul className='list sub'>
                    {items.map((item, iIdx) => {
                      const {
                        category_item_name: categoryItemName, category_item_number: categoryItemNumber, amount: iAmount,
                        received_amount: iReceivedAmount, jid_number: jidNumber, checked: iChecked
                      } = item
                      const fieldPrefix = `invoices[${idx}].items[${iIdx}]`
                      const receivedAmount = getFieldValue(`${fieldPrefix}.received_amount`)
                      const receivedDate = getFieldValue(`${fieldPrefix}.received_date`)
                      const comment = getFieldValue(`${fieldPrefix}.comment`)
                      const closed = getFieldValue(`${fieldPrefix}.closed`) && validator.isNullOrUndefined(receivedAmount)
                      const show = iChecked && (
                        !validator.isNullOrUndefined(receivedAmount) || !validator.isNullOrUndefined(receivedDate) || closed
                      )

                      return show ? (
                        <li key={iIdx}>
                          <div>{jidNumber} - {categoryItemName} {categoryItemNumber ? `(${categoryItemNumber})` : ''}:</div>
                          {receivedAmount ? <div className='content'>Receive Amount: {formatter.toPrice(receivedAmount)}</div> : null}
                          {receivedDate ? <div className='content'>Receive Date: {formatter.toShortDate(receivedDate)}</div> : null}
                          {comment ? <div className='content'>Receive Notes: {comment}</div> : null}
                          {closed ? (
                            <div className='content'>
                              <div>Is Closed?: {formatter.toYesNo(closed)}</div>

                              <div className='ip-text-error'>
                                <div>Expected Receive Amount: {formatter.toPrice(iAmount)}</div>
                                <div>Actual Received Amount: {formatter.toPrice(iReceivedAmount)}</div>
                              </div>
                            </div>
                          ) : null}
                        </li>
                      ) : null
                    })}
                  </ul>
                ) : null}
              </li>
            ) : null
          }) : null}
        </ul>
      </div>
    )
    title = 'Update Received Payment?'
  } else if (action === InvoiceUpdateType.INV_UPDATE_REJECTED) {
    content = (
      <div>
        <div className='title'>Do you want to reject the following invoice{isSingle ? '' : 's'}?</div>
        <ul className='list'>
          {isArray ? list.map(({
            client_first_name: clientFirstName, client_last_name: clientLastName, provider_name: providerName,
            invoice_number: invoiceNumber, invoice_provider_name: invoiceProviderName, amount, processed_at: processAt
          }, idx) => (
            <li key={idx} className='title'>
              <div>{invoiceNumber} ({formatter.toPrice(amount)}) - processed at {formatter.toShortDate(processAt)}</div>
              <div>for <b>{clientFirstName} {clientLastName}</b></div>
              {providerName || invoiceProviderName ? <div>with <i>{providerName || invoiceProviderName}</i></div> : null}
            </li>
          )) : null}
        </ul>
      </div>
    )
    title = `Reject Invoice${isSingle ? '' : 's'}?`
  }

  return { content, title }
}

function getInvoiceColumns (hasAccess, status, onChangeCheckbox, openRejectModal) {
  let columns = []

  const id = {
    title: 'JID',
    width: 2.5,
    render: ({
      jid_number: jidNumber, private_comment: privateComment, status, comm_scheduled_id: commScheduledId,
      comm_scheduled_at: commScheduledAt, error_count: errorCount
    }) => {
      return (
        <span>
          <span>{formatter.capitalize(jidNumber, false)}</span>

          {([InvoiceStatus.Processing.value, InvoiceStatus.PendingAuthorise.value].indexOf(status) > -1 && commScheduledId)
            ? (
              <span>&nbsp;
                <Tooltip mouseLeaveDelay={0} title={`Comm scheduled at ${formatter.toStandardDate(commScheduledAt)}`} >
                  <Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '11pt', cursor: 'pointer' }} />
                </Tooltip>
              </span>
            )
            : null}

          {privateComment
            ? (
              <span>&nbsp;
                <Tooltip mouseLeaveDelay={0} title={`Private Notes: ${privateComment || ''}`} >
                  <Icon type='edit' theme='filled' style={{ color: '#eb7a34', fontSize: '11pt', cursor: 'pointer' }} />
                </Tooltip>
              </span>
            )
            : null}

          {errorCount > 0
            ? (
              <span>&nbsp;
                <Tooltip mouseLeaveDelay={0} title='The Plan Period associated with this invoice is no longer active/within period/deleted.'>
                  <Icon type='warning' theme='filled' style={{ color: 'red', fontSize: '12pt', cursor: 'pointer' }} />
                </Tooltip>
              </span>
            )
            : null}
        </span>
      )
    }
  }

  const processAt = {
    title: 'Processed',
    width: 3,
    render: ({ processed_at: processedAt }) => {
      return processedAt
        ? <>{formatter.toShortDate(processedAt)} ({formatter.toDayCount(processedAt)})</>
        : <>N/A</>
    }
  }

  const authedDate = {
    title: "Auth'd Date",
    width: 3,
    render: ({ status_date: statusDate }) => {
      return statusDate
        ? <>{formatter.toShortDate(statusDate)} ({formatter.toDayCount(statusDate)})</>
        : <>N/A</>
    }
  }

  const toPayDate = {
    title: "Received Date",
    width: 3,
    render: ({ status_date: statusDate }) => {
      return statusDate
        ? <>{formatter.toShortDate(statusDate)} ({formatter.toDayCount(statusDate)})</>
        : <>N/A</>
    }
  }

  const invDate = {
    title: 'Inv Date',
    width: 2,
    render: ({ invoice_date: invoiceDate }) => formatter.toShortDate(invoiceDate)
  }

  const invNo = {
    title: 'Inv No.',
    width: 2,
    render: ({ invoice_number: invoiceNo }) => invoiceNo
  }

  const client = {
    title: 'Participant',
    width: 4,
    render: ({ client_ref_id: clientRefId, client_first_name: clientFirstName, client_last_name: clientLastName }) => (
      <div>
        <Link to={`/participants/${clientRefId}/info`} rel='noopener noreferrer' target='_blank'>
          {clientFirstName} {clientLastName}
        </Link>
      </div>
    )
  }

  const provider = {
    title: 'Provider',
    width: 4,
    render: ({ provider_ref_id: providerRefId, provider_name: providerName, invoice_provider_name: invoiceProviderName }) => {
      return providerRefId
        ? (
          <div>
            <Link to={`/providers/${providerRefId}/info`} rel='noopener noreferrer' target='_blank'>{providerName}</Link>
          </div>
        )
        : invoiceProviderName
          ? (
            <div className='has-indicator'>
              <span className='invoice-indicator'>R</span>
              <span>{invoiceProviderName}</span>
            </div>
          )
          : <div>N/A</div>
    }
  }

  const itemQty = {
    title: 'Item',
    width: 1,
    render: ({ item_count: itemCount }) => itemCount
  }

  const invSubtotal = {
    title: 'Inv Total',
    width: 2,
    render: ({ amount }) => formatter.toPrice(amount)
  }

  const invRcvSubtotal = {
    title: 'Rcv Total',
    width: 2,
    render: ({ received_amount: receivedAmount }) => formatter.toPrice(receivedAmount)
  }

  const statusCol = {
    title: 'Status',
    width: 2,
    render: ({ status_name: statusName, status_color: statusColor }) => (
      <div className='status-indicator' style={{ backgroundColor: statusColor || undefined }}>{statusName}</div>
    )
  }

  const draftedDate = {
    title: 'Drafted At',
    width: 3,
    render: ({ status_date: statusDate }) => formatter.toStandardLongDate(statusDate)
  }

  const rejectedDate = {
    title: 'Rejected At',
    width: 3,
    render: ({ status_date: statusDate }) => formatter.toStandardLongDate(statusDate)
  }

  const cancelledDate = {
    title: 'Cancelled At',
    width: 3,
    render: ({ status_date: statusDate }) => formatter.toStandardLongDate(statusDate)
  }

  const actionsAuthorise = {
    title: 'Actions',
    width: 1,
    render: (item, idx) => {
      const { status: iStatus, is_delete: deleted } = item
      const isStatusMatched = iStatus === status
      const isDeleted = deleted === true

      return isDeleted
        ? null
        : (
          <div key='authorise' className='ip-action-box'>
            {/* {isStatusMatched ? <InvAuthoriseButton hasAccess={hasAccess} item={item} /> : null} */}
            {isStatusMatched ? <InvRejectButton hasAccess={hasAccess} item={item} onClick={openRejectModal} /> : null}
            {isStatusMatched ? <InvCheckbox hasAccess={hasAccess} idx={idx} item={item} onChange={onChangeCheckbox} /> : null}
            <InvViewButton hasAccess={hasAccess} item={item} />
          </div>
        )
    }
  }

  const actionsClaim = {
    title: 'Actions',
    width: 1,
    render: (item, idx) => {
      const { status: iStatus, is_delete: deleted } = item
      const isStatusMatched = iStatus === status
      const isDeleted = deleted === true

      return isDeleted
        ? null
        : (
          <div key='claim' className='ip-action-box'>
            {isStatusMatched ? <InvRejectButton hasAccess={hasAccess} item={item} onClick={openRejectModal} /> : null}
            {isStatusMatched ? <InvCheckbox hasAccess={hasAccess} idx={idx} item={item} onChange={onChangeCheckbox} /> : null}
            <InvViewButton hasAccess={hasAccess} item={item} />
          </div>
        )
    }
  }

  const actionsReject = {
    title: 'Actions',
    width: 1,
    render: (item) => {
      const { status: iStatus, is_delete: deleted } = item
      const isStatusMatched = iStatus === status
      const isDeleted = deleted === true
      const isUpdated = iStatus === InvoiceStatus.Rejected

      return isDeleted
        ? null
        : (
          <div key='reject' className='ip-action-box'>
            {isStatusMatched && !isUpdated ? <InvRejectButton hasAccess={hasAccess} item={item} onClick={openRejectModal} /> : null}
            <InvViewButton hasAccess={hasAccess} item={item} />
          </div>
        )
    }
  }

  const actionsDrafted = {
    title: 'Actions',
    width: 1,
    render: (item, idx) => {
      const { status: iStatus, is_delete: deleted } = item
      const isStatusMatched = iStatus === status
      const isDeleted = deleted === true

      return isDeleted
        ? null
        : (
          <div key='drafted' className='ip-action-box'>
            {isStatusMatched
              ? <InvCheckbox hasAccess={hasAccess} idx={idx} item={item} onChange={onChangeCheckbox} onClick={openRejectModal} />
              : null}
            <InvViewButton hasAccess={hasAccess} item={item} />
          </div>
        )
    }
  }

  const actionsToPay = {
    title: 'Actions',
    width: 1,
    render: (item, idx) => {
      const { status: iStatus, is_delete: deleted } = item
      const isStatusMatched = iStatus === status
      const isDeleted = deleted === true

      return isDeleted
        ? null
        : (
          <div key='topay' className='ip-action-box'>
            {isStatusMatched ? <InvRejectButton hasAccess={hasAccess} item={item} onClick={openRejectModal} /> : null}
            {isStatusMatched ? <InvCheckbox hasAccess={hasAccess} idx={idx} item={item} onChange={onChangeCheckbox} /> : null}
            <InvViewButton hasAccess={hasAccess} item={item} />
          </div>
        )
    }
  }

  const actions = {
    title: 'Actions',
    width: 1,
    render: (item) => {
      const isDeleted = item.is_delete === true

      return isDeleted
        ? null
        : (
          <div key='normal' className='ip-action-box'>
            <InvViewButton hasAccess={hasAccess} item={item} />
          </div>
        )
    }
  }

  if (status === InvoiceStatus.ToAuthorise.value) {
    columns = [id, processAt, invDate, invNo, client, provider, itemQty, invSubtotal, invRcvSubtotal, actionsAuthorise]
  } else if (status === InvoiceStatus.ToClaim.value) {
    columns = [id, authedDate, invDate, invNo, client, provider, itemQty, invSubtotal, invRcvSubtotal, actionsClaim]
  } else if (status === InvoiceStatus.ToReceive.value) {
    columns = [id]
  } else if (status === InvoiceStatus.ToPay.value) {
    columns = [id, toPayDate, invDate, invNo, client, provider, itemQty, invSubtotal, invRcvSubtotal, actionsToPay]
  } else if (status === InvoiceStatus.Drafted.value) {
    columns = [id, processAt, invDate, invNo, client, provider, itemQty, invSubtotal, invRcvSubtotal, draftedDate, actionsDrafted]
  } else if (status === InvoiceStatus.Rejected.value) {
    columns = [id, processAt, invDate, invNo, client, provider, itemQty, invSubtotal, invRcvSubtotal, rejectedDate, actionsReject]
  } else if (status === InvoiceStatus.Cancelled.value) {
    columns = [id, processAt, invDate, invNo, client, provider, itemQty, invSubtotal, invRcvSubtotal, cancelledDate, actions]
  } else if (status === InvoiceStatus.All.value) {
    columns = [id, processAt, invDate, invNo, client, provider, itemQty, invSubtotal, invRcvSubtotal, statusCol, actions]
  }

  return Object.freeze(columns)
}

function getListFilterInvoiceType (type) {
  return type === InvoiceListType.INV_LIST_STD ? InvoiceType.INV_TYPE_STD.value : Object.freeze([InvoiceType.INV_TYPE_PM.value, InvoiceType.INV_TYPE_RMB.value])
}

function getListFilterStatus (selectedStatus, type) {
  const toAuthFilter = Object.freeze([
    InvoiceStatus.Processing.value, InvoiceStatus.PendingAuthorise.value, InvoiceStatus.ToAuthorise.value
  ])

  if (selectedStatus === InvoiceStatus.All.value) {
    return undefined
  } else if (!selectedStatus || selectedStatus === InvoiceStatus.ToAuthorise.value) {
    return type === InvoiceListType.INV_LIST_STD ? InvoiceStatus.ToClaim.value : toAuthFilter
  }

  return selectedStatus
}

function getListSorting (selectedStatus) {
  if (selectedStatus === InvoiceStatus.All.value) {
    return SortingAll
  } else if (selectedStatus === InvoiceStatus.ToReceive.value) {
    return SortingToReceive
  }

  return SortingOthers
}

function getToReceiveColumnWidth (width) {
  return { width: `${(100 * width / 36).toFixed(2)}%` }
}

function getToReceiveBottomColumns (props, onChangeCheckbox, onToggleCommentVisible) {
  const { form } = props || {}
  const { getFieldDecorator, getFieldValue, setFieldsValue, validateFields } = form || {}

  const changeCheckbox = (aIdx, idx, item) => ({ target }) => {
    const { checked } = target

    if (typeof onChangeCheckbox === 'function') {
      item.checked = !checked
      onChangeCheckbox(aIdx, idx, item)
    }
  }

  const changeClosed = (aIdx, idx, item) => (closed) => {
    const fieldPrefix = `invoices[${aIdx}].items[${idx}]`
    const receivedAmount = getFieldValue(`${fieldPrefix}.received_amount`)
    const receivedDate = getFieldValue(`${fieldPrefix}.received_date`)
    const isBlankReceivedAmount = validator.isNullOrUndefined(receivedAmount)
    const isBlankReceivedDate = validator.isNullOrUndefined(receivedDate)
    const checked = isBlankReceivedAmount && isBlankReceivedDate ? closed : (!isBlankReceivedAmount || closed)

    if (isBlankReceivedAmount && closed) {
      setFieldsValue({ [`${fieldPrefix}.received_date`]: undefined })
    }

    if (typeof onChangeCheckbox === 'function') {
      item.checked = checked
      onChangeCheckbox(aIdx, idx, item)
    }
  }

  const changeComment = (aIdx, idx, item) => ({ target }) => {
    const fieldPrefix = `invoices[${aIdx}].items[${idx}]`
    const { checked } = item
    const { value } = target || {}
    const receivedAmountStr = getFieldValue(`${fieldPrefix}.received_amount`)
    const receivedAmount = formatter.toBigNumber(receivedAmountStr)
    const receivedDate = getFieldValue(`${fieldPrefix}.received_date`)
    const closed = getFieldValue(`${fieldPrefix}.closed`)
    const isBlankReceivedAmount = validator.isNullOrUndefined(receivedAmountStr) || validator.isEmptyString(receivedAmountStr)
    const isBlankComment = validator.isNullOrUndefined(value) || validator.isEmptyString(value)
    const isInvalidAmount = (
      !validator.isCurrencyAmount(value) || receivedAmount.isNaN() || !receivedAmount.isFinite() ||
      receivedAmount.decimalPlaces() > 2 || receivedAmount.isLessThanOrEqualTo(0)
    )
    setFieldsValue({
      [`${fieldPrefix}.received_date`]: isBlankReceivedAmount && isBlankComment
        ? undefined : validator.isNullOrUndefined(receivedDate) ? moment().startOf('day') : receivedDate
    })

    if (isInvalidAmount) {
      const timer = setTimeout(() => {
        clearTimeout(timer)
        validateFields([`${fieldPrefix}.received_amount`], { force: isBlankComment })
      }, isBlankComment ? 100 : 0)
    }

    if (typeof onChangeCheckbox === 'function') {
      item.checked = isBlankReceivedAmount ? !isBlankComment || closed : checked
      onChangeCheckbox(aIdx, idx, item)
    }
  }

  const changeReceivedAmount = (aIdx, idx, item) => ({ target }) => {
    const fieldPrefix = `invoices[${aIdx}].items[${idx}]`
    const { remaining_amount: remainingAmount } = item
    const { value } = target || {}
    const receivedAmount = formatter.toBigNumber(value)
    const receivedDate = getFieldValue(`${fieldPrefix}.received_date`)
    const isInvalidAmount = (
      !validator.isCurrencyAmount(value) || receivedAmount.isNaN() || !receivedAmount.isFinite() ||
      receivedAmount.decimalPlaces() > 2 || receivedAmount.isGreaterThan(remainingAmount)
    )
    setFieldsValue({
      [`${fieldPrefix}.received_date`]: isInvalidAmount
        ? undefined
        : validator.isNullOrUndefined(receivedDate) ? moment().startOf('day') : receivedDate,
      [`${fieldPrefix}.closed`]: isInvalidAmount ? false : receivedAmount.isEqualTo(remainingAmount)
    })

    if (typeof onChangeCheckbox === 'function') {
      const checked = !isInvalidAmount
      item.checked = checked
      onChangeCheckbox(aIdx, idx, item)
    }
  }

  const getPastedText = (event) => {
    event.preventDefault()
    const clipboardData = event.clipboardData || window.clipboardData
    return clipboardData.getData('text')
  }

  const pasteAmount = (aIdx, idx, item, field) => (event) => {
    const pastedText = getPastedText(event)

    if (pastedText) {
      const cleanedData = formatter.parseAmount(pastedText)
      changeReceivedAmount(aIdx, idx, item)({ target: { value: cleanedData } })
      setFieldsValue({ [field]: cleanedData })
      validateFields([field])
    }
  }

  const toggleCommentVisible = (aIdx, idx, item) => (visible) => {
    if (typeof onToggleCommentVisible === 'function') {
      item.is_comment_visible = visible
      onToggleCommentVisible(aIdx, idx, item)
    }
  }

  const validateReceivedAmount = (remainingAmount) => (rule, value, callback) => {
    if (!validator.isNullOrUndefined(value) && !validator.isEmptyString(value, true)) {
      const receivedAmount = formatter.toBigNumber(value)

      if (
        !validator.isCurrencyAmount(value) || receivedAmount.isNaN() || !receivedAmount.isFinite() || receivedAmount.decimalPlaces() > 2
      ) {
        callback(new Error('Please enter a number with 2 decimal places'))
      } else if (receivedAmount.isGreaterThan(remainingAmount)) {
        callback(new Error('Receive amount cannot exceed remaining amount'))
      } else {
        callback()
      }
    } else {
      callback()
    }
  }

  const validateReceivedDate = (invoiceDate) => (rule, value, callback) => {
    if (!validator.isNullOrUndefined(value) && moment(value).isValid()) {
      if (moment(value).isBefore(moment(invoiceDate).startOf('day'))) {
        callback(new Error(`Receive date must be same as or after invoice date`))
      } else {
        callback()
      }
    } else {
      callback()
    }
  }

  return Object.freeze([
    {
      title: '',
      width: 1,
      render: ({ jid_number: jidNumber }) => <div className='text'>{formatter.capitalize(jidNumber, false)}</div>
    },
    {
      title: 'Service Start',
      width: 2,
      render: ({ start_date: startDate }) => <div className='text'>{formatter.toShortDate(startDate)}</div>
    },
    {
      title: 'Service End',
      width: 2,
      render: ({ end_date: endDate }) => <div className='text'>{formatter.toShortDate(endDate)}</div>
    },
    {
      title: 'Item Number',
      width: 6,
      render: ({ category_item_name: categoryItemName, category_item_number: categoryItemNumber }) => (
        <div className='text'>{categoryItemName} ({categoryItemNumber})</div>
      )
    },
    {
      title: 'Unit',
      width: 1,
      render: ({ unit }) => <div className='text'>{formatter.toBigNumber(unit).toNumber()}</div>
    },
    {
      title: 'Inv Rate',
      width: 2,
      render: ({ rate }) => <div className='text'>{formatter.toPrice(rate)}</div>
    },
    {
      title: 'Inv Amt',
      width: 2,
      render: ({ amount }) => <div className='text'>{formatter.toPrice(amount)}</div>
    },
    {
      title: 'Rcv\'d',
      width: 2,
      render: ({ received_amount: receivedAmount, closed }) => (
        <div className='text' style={{ color: closed ? '#2ec77a' : undefined }}>{formatter.toPrice(receivedAmount)}</div>
      )
    },
    {
      title: 'Remaining',
      width: 2,
      render: ({ remaining_amount: remainingAmount, closed }) => (
        <div className='text' style={{ color: closed ? '#2ec77a' : undefined }}>{formatter.toPrice(remainingAmount)}</div>
      )
    },
    {
      title: 'Credit?',
      width: 1,
      render: ({ credit_id: creditId }) => (
        <div className='input-box'>
          {validator.isId(creditId)
            ? <Icon type='check-circle' theme='filled' style={{ color: '#2ec77a' }} />
            : <Icon type='close-circle' theme='filled' style={{ color: '#aaa' }} />}
        </div>
      )
    },
    {
      title: 'Rcv Amt',
      width: 4,
      render: (item, idx, additional) => {
        const { remaining_amount: remainingAmount, closed } = item
        const { idx: aIdx } = additional || {}
        const fieldPrefix = `invoices[${aIdx}].items[${idx}]`
        const fieldName = `${fieldPrefix}.received_amount`
        const receivedAmount = formatter.toBigNumber(getFieldValue(fieldName))
        const comment = getFieldValue(`${fieldPrefix}.comment`)
        const isFullAmount = receivedAmount.isGreaterThan(0) ? receivedAmount.minus(remainingAmount).isEqualTo(0) : true

        return (
          <FormItem
            extra={!isFullAmount ? <span className='ip-text-error'>NOT full amount</span> : null}
            hasFeedback={isFullAmount}
          >
            {getFieldDecorator(fieldName, {
              rules: [
                {
                  required: !validator.isNullOrUndefined(comment) && !validator.isEmptyString(comment),
                  message: 'Please enter receive amount'
                },
                { validator: validateReceivedAmount(remainingAmount) }
              ]
            })(
              <Input
                addonBefore='$'
                disabled={closed}
                onChange={changeReceivedAmount(aIdx, idx, item)}
                onPaste={pasteAmount(aIdx, idx, item, fieldName)}
              />
            )}
          </FormItem>
        )
      }
    },
    {
      title: 'Rcv Date',
      width: 1,
      render: ({ closed }, idx, additional) => {
        const { idx: aIdx, invoice_date: invoiceDate } = additional || {}

        return (
          <FormItem style={{ width: '120px' }}>
            {getFieldDecorator(`invoices[${aIdx}].items[${idx}].received_date`, {
              rules: [
                { validator: validateReceivedDate(invoiceDate) }
              ]
            })(
              <DatePicker disabled={closed} format={dateFormat} placeholder='' />
            )}
          </FormItem>
        )
      }
    },
    {
      title: 'Rcv Notes',
      width: 2,
      render: (item, idx, additional) => {
        const { closed, is_comment_visible: visible } = item
        const { idx: aIdx } = additional || {}
        const fieldName = `invoices[${aIdx}].items[${idx}].comment`
        const comment = getFieldValue(fieldName)
        const hasComment = typeof comment === 'string' && comment.trim().length > 0
        const content = (
          <FormItem>
            {getFieldDecorator(fieldName, {
              rules: [
                { whitespace: true, message: 'Please enter receive notes' }
              ]
            })(
              <TextArea disabled={closed} rows={5} onChange={changeComment(aIdx, idx, item)} />
            )}
          </FormItem>
        )

        return (
          <Popover content={content} trigger='click' onVisibleChange={toggleCommentVisible(aIdx, idx, item)}>
            <div className='input-box'>
              <Icon
                className={clsx('item-comment', hasComment ? 'highlight' : '')}
                type={visible ? 'minus-circle' : 'plus-circle'}
                theme='filled'
              />
            </div>
          </Popover>
        )
      }
    },
    {
      title: 'Closed?',
      width: 1,
      render: (item, idx, additional) => {
        const { closed } = item
        const { idx: aIdx } = additional || {}

        return (
          <FormItem>
            {getFieldDecorator(`invoices[${aIdx}].items[${idx}].closed`, {
              initialValue: closed,
              valuePropName: 'checked'
            })(
              <Switch
                checkedChildren='Yes'
                unCheckedChildren='No'
                disabled={closed}
                onChange={changeClosed(aIdx, idx, item)}
              />
            )}
          </FormItem>
        )
      }
    },
    {
      title: '',
      width: 1,
      render: (item, idx, additional) => {
        const {
          id, category_number: categoryNumber, category_name: categoryName, category_item_number: categoryItemNumber,
          category_item_name: categoryItemName, checked, closed
        } = item
        const { idx: aIdx, invoice_id: invoiceId } = additional || {}

        return (
          <div className='input-box'>
            <Checkbox disabled={closed} checked={checked} onClick={changeCheckbox(aIdx, idx, item)} />

            {getFieldDecorator(`invoices[${aIdx}].items[${idx}].invoice_id`, {
              initialValue: invoiceId
            })(
              <Input hidden />
            )}

            {getFieldDecorator(`invoices[${aIdx}].items[${idx}].invoice_item_id`, {
              initialValue: id
            })(
              <Input hidden />
            )}

            {getFieldDecorator(`invoices[${aIdx}].items[${idx}].category_number`, {
              initialValue: categoryNumber
            })(
              <Input hidden />
            )}

            {getFieldDecorator(`invoices[${aIdx}].items[${idx}].category_name`, {
              initialValue: categoryName
            })(
              <Input hidden />
            )}

            {getFieldDecorator(`invoices[${aIdx}].items[${idx}].category_item_number`, {
              initialValue: categoryItemNumber
            })(
              <Input hidden />
            )}

            {getFieldDecorator(`invoices[${aIdx}].items[${idx}].category_item_name`, {
              initialValue: categoryItemName
            })(
              <Input hidden />
            )}
          </div>
        )
      }
    }
  ])
}

function getToReceiveTopColumns (hasAccess, status, openRejectModal) {
  return Object.freeze([
    {
      title: 'JID',
      width: 2.5,
      render: ({
        jid_number: jidNumber, private_comment: privateComment, status, comm_scheduled_id: commScheduledId,
        comm_scheduled_at: commScheduledAt, error_count: errorCount
      }) => {
        return (
          <span className='ip-action-box'>
            <span>{formatter.capitalize(jidNumber, false)}</span>

            {(status === InvoiceStatus.PendingAuthorise.value && commScheduledId)
              ? <span>&nbsp;
                <Tooltip mouseLeaveDelay={0} title={`Comm scheduled at ${formatter.toStandardDate(commScheduledAt)}`} >
                  <Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '11pt', cursor: 'pointer' }} />
                </Tooltip>
              </span>
              : null}

            {privateComment
              ? (
                <span>&nbsp;
                  <Tooltip mouseLeaveDelay={0} title={`Private Notes: ${privateComment || ''}`} >
                    <Icon type='edit' theme='filled' style={{ color: '#eb7a34', fontSize: '11pt', cursor: 'pointer' }} />
                  </Tooltip>
                </span>
              )
              : null}

            {errorCount > 0
              ? (
                <span>&nbsp;
                  <Tooltip mouseLeaveDelay={0} title='The Plan Period associated with this invoice is no longer active/within period/deleted.'>
                    <Icon type='warning' theme='filled' style={{ color: 'red', fontSize: '12pt', cursor: 'pointer' }} />
                  </Tooltip>
                </span>
              )
              : null}
          </span>
        )
      }
    },
    {
      title: 'Claimed Date',
      width: 3,
      render: ({ status_date: statusDate }) => {
        return statusDate
          ? <>{formatter.toShortDate(statusDate)} ({formatter.toDayCount(statusDate)})</>
          : <>N/A</>
      }
    },
    {
      title: 'Inv Date',
      width: 2,
      render: ({ invoice_date: invoiceDate }) => formatter.toShortDate(invoiceDate)
    },
    {
      title: 'Inv No.',
      width: 2,
      render: ({ invoice_number: invoiceNumber }) => invoiceNumber
    },
    {
      title: 'Participant',
      width: 4,
      render: ({ client_ref_id: clientRefId, client_first_name: clientFirstName, client_last_name: clientLastName }) => (
        <Link to={`/participants/${clientRefId}/info`} rel='noopener noreferrer' target='_blank'>
          {clientFirstName} {clientLastName}
        </Link>
      )
    },
    {
      title: 'Provider',
      width: 4,
      render: ({ provider_ref_id: providerRefId, provider_name: providerName, invoice_provider_name: invoiceProviderName }) => {
        return providerRefId
          ? (
            <div>
              <Link to={`/providers/${providerRefId}/info`} rel='noopener noreferrer' target='_blank'>{providerName}</Link>
            </div>
          )
          : invoiceProviderName
            ? (
              <span>
                <span>R</span>
                <span>{invoiceProviderName}</span>
              </span>
            )
            : <div>N/A</div>
      }
    },
    {
      title: 'Item',
      width: 1,
      render: ({ item_count: itemCount }) => itemCount
    },
    {
      title: 'Inv Total',
      width: 2,
      render: ({ amount }) => formatter.toPrice(amount)
    },
    {
      title: 'Rcv Total',
      width: 2,
      render: ({ received_amount: receivedAmount }) => formatter.toPrice(receivedAmount)
    },
    {
      title: 'Actions',
      width: 1,
      render: (item) => {
        const isStatusMatched = item.status === status
        const isDeleted = item.deleted === true

        return isDeleted
          ? null
          : (
            <div key='authorise' className='ip-action-box'>
              {isStatusMatched ? <InvRejectButton hasAccess={hasAccess} item={item} onClick={openRejectModal} /> : null}
              <InvViewButton hasAccess={hasAccess} item={item} />
            </div>
          )
      }
    }
  ])
}

function getToReceiveListData (bottomRows, topRows) {
  const itemsMap = new Map()

  if (Array.isArray(bottomRows)) {
    for (let i = 0; i < bottomRows.length; i++) {
      const row = bottomRows[i]
      const key = row.invoice_id.toString()
      let items = itemsMap.get(key)

      if (!Array.isArray(items)) {
        items = []
      }

      items.push(row)
      itemsMap.set(key, items)
    }
  }

  for (let i = 0; i < topRows.length; i++) {
    const row = topRows[i]
    row.items = itemsMap.get(row.id.toString())
  }

  return topRows
}

function InvoiceBulkUpdateModal ({ action, list, props, showModal, onCloseModal, onUpdateSuccess }) {
  const { form } = props || {}
  const { resetFields, validateFields } = form
  const { content, title } = getInvoiceBulkUpdateModalData(action, props, list)
  const [updating, setUpdating] = useState(false)

  const closeModal = useCallback(() => {
    if (updating) {
      return
    }

    if (typeof onCloseModal === 'function') {
      onCloseModal()
    }
  }, [onCloseModal, updating])

  const handleUpdateSuccess = useCallback((updated) => {
    if (updating) {
      return
    }

    if (typeof onUpdateSuccess === 'function') {
      onUpdateSuccess(() => { setUpdating(false) }, action, updated)
    }
  }, [onUpdateSuccess, action, updating])

  const handleUpdate = useCallback(async () => {
    if (updating) {
      return
    }

    const errorTitle = `Unable to ${action}`

    if (
      [
        InvoiceUpdateType.INV_UPDATE_ABA_RMT, InvoiceUpdateType.INV_UPDATE_AUTHORISED, InvoiceUpdateType.INV_UPDATE_DELETE,
        InvoiceUpdateType.INV_UPDATE_PYMT_REQUEST, InvoiceUpdateType.INV_UPDATE_RCV_PYMT, InvoiceUpdateType.INV_UPDATE_REJECTED,
      ].indexOf(action) < 0 || list.length < 1
    ) {
      notify.error(errorTitle, messageInvalidBulkUpdateAction)
    }

    const values = { invoices: list.map(({ id, items }) => ({ id, items })) }
    let updateError
    let updateAction

    if (action === InvoiceUpdateType.INV_UPDATE_ABA_RMT) {
      updateAction = 'pay'

      try {
        const result = await validateFields(['payment_date'])
        values.payment_date = result.payment_date.toISOString()
      } catch (e) {
        updateError = e
      }
    } else if (action === InvoiceUpdateType.INV_UPDATE_AUTHORISED) {
      updateAction = 'authorise'
    } else if (action === InvoiceUpdateType.INV_UPDATE_DELETE) {
      updateAction = 'delete'
    } else if (action === InvoiceUpdateType.INV_UPDATE_PYMT_REQUEST) {
      updateAction = 'claim'
    } else if (action === InvoiceUpdateType.INV_UPDATE_RCV_PYMT) {
      updateAction = 'receive'

      try {
        const result = await validateFields(['invoices'])

        if (result && Array.isArray(result.invoices)) {
          const { invoices } = values
          const itemValues = result.invoices.flat()
          const itemValueLookup = new Map()
          const checkedInvoices = []

          for (const { items } of itemValues) {
            if (Array.isArray(items)) {
              for (const item of items) {
                itemValueLookup.set(item.invoice_item_id, item)
              }
            }
          }

          for (let i = 0; i < invoices.length; i++) {
            const invoice = invoices[i]
            const { items } = invoice
            const checkedItems = Array.isArray(items) ? items.filter(({ checked }) => checked) : []

            if (checkedItems.length > 0) {
              const checkedItemValues = []

              for (const { id: invoiceItemId, invoice_id: invoiceId } of checkedItems) {
                const checkedItemValue = itemValueLookup.get(invoiceItemId)

                if (!validator.isNullOrUndefined(checkedItemValue)) {
                  const { received_amount: receivedAmount, received_date: receivedDate } = checkedItemValue
                  checkedItemValue.received_amount = validator.isNullOrUndefined(receivedAmount)
                    ? undefined : formatter.toBigNumber(receivedAmount).toFixed(2)
                  checkedItemValue.received_date = validator.isNullOrUndefined(receivedDate)
                    ? undefined : receivedDate.toISOString()
                  checkedItemValues.push(checkedItemValue)
                }
              }

              invoice.items = checkedItemValues
              checkedInvoices.push(invoice)
            }
          }

          values.invoices = checkedInvoices
        }
      } catch (e) {
        updateError = e
      }
    } else if (action === InvoiceUpdateType.INV_UPDATE_REJECTED) {
      updateAction = 'reject'
    }

    if (updateError) {
      return
    }

    setUpdating(true)
    invoiceBulkPaceService
      .bulkUpdate(updateAction, values)
      .then((response) => {
        if (validator.isObject(response)) {
          // const { count, invoices, partial, success, updated } = response
          const { success, file, updated } = response
          if (success === true) {
            notify.success(`Updated Successfully`, `Invoice(s) updated successfully.`)
            handleUpdateSuccess(updated)

            if (action === InvoiceUpdateType.INV_UPDATE_RCV_PYMT) {
              resetFields()
            }

            if (file && validator.isId(file.id)) {
              if (file.type === ExportType.TYPE_PAYMENT) {
                file.type = ExportType.TYPE_ABA
              }

              exportFilePace.fetchFile(file)
            }
          } else {
            notify.error(errorTitle, `Unable to ${action} successfully. Please try again later.`)
            setUpdating(false)
          }
        }
      })
      .catch((e) => {
        notify.error(errorTitle, `Unable to ${action} successfully. Please try again later.`)
        console.log(e)
        setUpdating(false)
      })
  }, [handleUpdateSuccess, resetFields, validateFields, action, list, updating])

  const resetModal = useCallback(async () => {
    setUpdating(false)
  }, [])

  return (
    <Modal
      className='ip-bulk-update-modal'
      footer={[
        <Button key='close' ghost feedback={updating} onClick={closeModal}>Cancel</Button>,
        <Button key='submit' feedback={updating} onClick={handleUpdate}>Confirm</Button>
      ]}
      title={<b>{title}</b>}
      visible={showModal}
      width={action === InvoiceUpdateType.INV_UPDATE_RCV_PYMT || action === InvoiceUpdateType.INV_UPDATE_REJECTED ? 600 : 900}
      onCancel={closeModal}
      onVisibleChange={resetModal}
    >
      {content}
    </Modal>
  )
}

function InvoiceExportModal ({ showModal, onCloseModal }) {
  const [checkboxValidation, setCheckboxValidation] = useState({})
  const [dateRange, setDateRange] = useState([])
  const [dateRangeValidation, setDateRangeValidation] = useState(defaultDateRangeValidation)
  const [exporting, setExporting] = useState(false)
  const [isDetailExport, setIsDetailExport] = useState(true)
  const [isMainExport, setIsMainExport] = useState(true)

  const changeDateRange = useCallback((dates) => {
    const validDates = Array.isArray(dates)
    const hasDates = validDates && dates.length > 0
    setDateRange(validDates ? dates : [])
    setDateRangeValidation(hasDates ? {} : defaultDateRangeValidation)
  }, [])

  const changeDetailExport = useCallback(({ target }) => {
    const { checked } = target
    const noneChecked = checked !== true && isMainExport !== true
    setCheckboxValidation(noneChecked ? defaultCheckboxValidation : {})
    setIsDetailExport(checked)
  }, [isMainExport])

  const changeMainExport = useCallback(({ target }) => {
    const { checked } = target
    const noneChecked = checked !== true && isDetailExport !== true
    setCheckboxValidation(noneChecked ? defaultCheckboxValidation : {})
    setIsMainExport(checked)
  }, [isDetailExport])

  const closeModal = useCallback(() => {
    if (exporting) {
      return
    }

    setCheckboxValidation({})
    setDateRange([])
    setDateRangeValidation(defaultDateRangeValidation)
    setIsDetailExport(true)
    setIsMainExport(true)

    if (typeof onCloseModal === 'function') {
      onCloseModal()
    }
  }, [onCloseModal, exporting])

  const handleExport = useCallback(() => {
    if (checkboxValidation.status === 'error' || exporting || dateRangeValidation.status === 'error') {
      return
    }

    setExporting(true)
    exportFilePace
      .fetchExport('invoice', {
        start_date: dateRange[0], end_date: dateRange[1], export_main: isMainExport, export_detail: isDetailExport
      })
      .catch((e) => {
        notify.error('Unable to export', 'Unable to export invoice successfully. Please try again later.')
        console.log(e)
      })
      .finally(() => {
        setExporting(false)
      })
  }, [checkboxValidation, dateRange, dateRangeValidation, exporting, isDetailExport, isMainExport])

  return (
    <Modal
      className='ip-export-modal'
      footer={[
        <Button key='close' ghost feedback={exporting} onClick={closeModal}>Cancel</Button>,
        <Button key='submit' feedback={exporting} onClick={handleExport}>Download</Button>
      ]}
      title='Export Invoices'
      visible={showModal}
      onCancel={closeModal}
    >
      <FormItem help={dateRangeValidation.message} validateStatus={dateRangeValidation.status}>
        <RangePicker allowClear format='DD/MM/YYYY' value={dateRange} onChange={changeDateRange} />
      </FormItem>

      <FormItem
        help={checkboxValidation.message}
        label='Please select item(s) to export'
        validateStatus={checkboxValidation.status}
      >
        <label className='label-checkbox'>
          <Checkbox className='ip-checkbox' checked={isMainExport} onChange={changeMainExport} />
          <span>Invoice Export</span>
        </label>

        <label className='label-checkbox'>
          <Checkbox className='ip-checkbox' checked={isDetailExport} onChange={changeDetailExport} />
          <span>Invoice Details Export</span>
        </label>
      </FormItem>
    </Modal>
  )
}

function InvoiceListSummary ({ loading, summary }) {
  return (
    <Panel className='ip-summary-panel'>
      <Spin spinning={loading}>
        <div className='summary-box'>
          <div className='item-box'>
            <span className='label'>Invoice Count :</span>
            <span className='value'>{loading ? 0 : (summary.count || 0)}</span>
          </div>

          <div className='item-box'>
            <span className='label'>Invoice Total Sum :</span>
            <span className='value'>{formatter.toPrice(loading ? 0 : summary.amount)}</span>
          </div>

          <div className='item-box'>
            <span className='label'>Client Count :</span>
            <span className='value'>{loading ? 0 : (summary.client_count || 0)}</span>
          </div>

          <div className='item-box'>
            <span className='label'>Provider Count :</span>
            <span className='value'>{loading ? 0 : (summary.provider_count || 0)}</span>
          </div>
        </div>
      </Spin>
    </Panel>
  )
}

function InvoiceSummary ({ loading, selectedStatus, summary, type, onChangeStatus }) {
  const overallSummary = validator.isObject(summary) ? summary : {}
  const isListStd = type === InvoiceListType.INV_LIST_STD
  const {
    count_drafted: countDrafted, count_authorised: countAuthorised, count_claim: countClaim, count_rcv: countRcv,
    count_topay: countToPay, count_rejected: countRejected, sum_authorised: sumAuthorised, sum_claim: sumClaim,
    sum_rcv: sumRcv, sum_topay: sumToPay
  } = overallSummary

  const changeStatus = useCallback((status) => () => {
    if (typeof onChangeStatus === 'function') {
      onChangeStatus(status)
    }
  }, [onChangeStatus])

  const isButtonActive = useCallback((status) => {
    return status === selectedStatus
  }, [selectedStatus])

  const reload = useCallback(() => {
    if (typeof window !== "undefined" && typeof window.location !== "undefined") {
      window.location.reload()
    }
  }, [])

  return (
    <div className='ip-margin-bottom'>
      <Spin spinning={loading}>
        <div className='ip-summary-box'>
          {isListStd ? (
            <>
              <div
                className={clsx('btn-box ip-btn', isButtonActive(InvoiceStatus.ToClaim.value) ? 'active' : '')}
                onClick={changeStatus(InvoiceStatus.ToClaim.value)}
              >
                <div className='value'>{loading ? 0 : (countClaim || 0)}</div>
                <div className='label'>To Claim</div>
                <div className='extra'>{formatter.toPrice(loading ? 0 : sumClaim)}</div>
              </div>

              <div
                className={clsx('btn-box ip-btn', isButtonActive(InvoiceStatus.ToReceive.value) ? 'active' : '')}
                onClick={changeStatus(InvoiceStatus.ToReceive.value)}
              >
                <div className='value'>{loading ? 0 : (countRcv || 0)}</div>
                <div className='label'>To Receive</div>
                <div className='extra'>{formatter.toPrice(loading ? 0 : sumRcv)}</div>
              </div>

              <div
                className={clsx('btn-box ip-btn', isButtonActive(InvoiceStatus.Drafted.value) ? 'active' : '')}
                onClick={changeStatus(InvoiceStatus.Drafted.value)}
              >
                <div className='value'>{loading ? 0 : (countDrafted || 0)}</div>
                <div className='label'>Drafts</div>
              </div>

              <div
                className={clsx('btn-box ip-btn', isButtonActive(InvoiceStatus.Rejected.value) ? 'active' : '')}
                onClick={changeStatus(InvoiceStatus.Rejected.value)}
              >
                <div className='value'>{loading ? 0 : (countRejected || 0)}</div>
                <div className='label'>Rejected</div>
              </div>

              <div className='btn-box'>
                <div
                  className={clsx('ip-btn', isButtonActive(InvoiceStatus.Cancelled.value) ? 'active' : '')}
                  onClick={changeStatus(InvoiceStatus.Cancelled.value)}
                >
                  Cancelled
                </div>
                <div
                  className={clsx('ip-btn', isButtonActive(InvoiceStatus.All.value) ? 'active' : '')}
                  onClick={changeStatus(InvoiceStatus.All.value)}
                >
                  All
                </div>
                <div className='ip-btn' onClick={reload}>Refresh</div>
              </div>
            </>
          ) : (
            <>
              <div
                className={clsx('btn-box ip-btn', isButtonActive(InvoiceStatus.ToAuthorise.value) ? 'active' : '')}
                onClick={changeStatus(InvoiceStatus.ToAuthorise.value)}
              >
                <div className='value'>{loading ? 0 : (countAuthorised || 0)}</div>
                <div className='label'>To Authorise</div>
                <div className='extra'>{formatter.toPrice(loading ? 0 : sumAuthorised)}</div>
              </div>

              <div
                className={clsx('btn-box ip-btn', isButtonActive(InvoiceStatus.ToClaim.value) ? 'active' : '')}
                onClick={changeStatus(InvoiceStatus.ToClaim.value)}
              >
                <div className='value'>{loading ? 0 : (countClaim || 0)}</div>
                <div className='label'>To Claim</div>
                <div className='extra'>{formatter.toPrice(loading ? 0 : sumClaim)}</div>
              </div>

              <div
                className={clsx('btn-box ip-btn', isButtonActive(InvoiceStatus.ToReceive.value) ? 'active' : '')}
                onClick={changeStatus(InvoiceStatus.ToReceive.value)}
              >
                <div className='value'>{loading ? 0 : (countRcv || 0)}</div>
                <div className='label'>To Receive</div>
                <div className='extra'>{formatter.toPrice(loading ? 0 : sumRcv)}</div>
              </div>

              <div
                className={clsx('btn-box ip-btn', isButtonActive(InvoiceStatus.ToPay.value) ? 'active' : '')}
                onClick={changeStatus(InvoiceStatus.ToPay.value)}
              >
                <div className='value'>{loading ? 0 : (countToPay || 0)}</div>
                <div className='label'>To Pay</div>
                <div className='extra'>{formatter.toPrice(loading ? 0 : sumToPay)}</div>
              </div>

              <div
                className={clsx('btn-box ip-btn', isButtonActive(InvoiceStatus.Drafted.value) ? 'active' : '')}
                onClick={changeStatus(InvoiceStatus.Drafted.value)}
              >
                <div className='value'>{loading ? 0 : (countDrafted || 0)}</div>
                <div className='label'>Drafts</div>
              </div>

              <div
                className={clsx('btn-box ip-btn', isButtonActive(InvoiceStatus.Rejected.value) ? 'active' : '')}
                onClick={changeStatus(InvoiceStatus.Rejected.value)}
              >
                <div className='value'>{loading ? 0 : (countRejected || 0)}</div>
                <div className='label'>Rejected</div>
              </div>

              <div className='btn-box'>
                <div
                  className={clsx('ip-btn', isButtonActive(InvoiceStatus.Cancelled.value) ? 'active' : '')}
                  onClick={changeStatus(InvoiceStatus.Cancelled.value)}
                >
                  Cancelled
                </div>
                <div
                  className={clsx('ip-btn', isButtonActive(InvoiceStatus.All.value) ? 'active' : '')}
                  onClick={changeStatus(InvoiceStatus.All.value)}
                >
                  All
                </div>
                <div className='ip-btn' onClick={reload}>Refresh</div>
              </div>
            </>
          )}
        </div>
      </Spin>
    </div>
  )
}

function ToReceiveImportModal ({ showModal, onCloseModal }) {
  const [uploadList, setUploadList] = useState([])
  const [uploadMessage, setUploadMessage] = useState()
  const [uploaded, setUploaded] = useState({})
  const [uploading, setUploading] = useState(false)

  const changeFile = useCallback((info) => {
    if (validator.isObject(info) && info.file) {
      const f = info.file
      const { percent, response: r, status, uid } = f

      if (percent === 100 && r && status === 'done') {
        const uploaded = {
          fileName: r.filePath ? r.filePath.filename : '',
          fileUrl: r.fileUrl,
          filePath: r.filePath ? r.filePath.path : '',
          uid: uid
        }
        setUploadMessage()
        setUploaded(uploaded)
      }
    }
  }, [])

  const checkUpload = useCallback((file) => {
    if (file && (
      file.type === 'text/csv' ||
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'application/vnd.ms-excel' ||
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.template'
    )) {
      setUploadList([file])
      setUploadMessage()
      return true
    } else {
      setUploadList([])
      setUploadMessage(FileUploadMsg.UploadMsgWrongFormatCSV)
      return false
    }
  }, [])

  const closeModal = useCallback(() => {
    if (uploading) {
      return
    }

    if (typeof onCloseModal === 'function') {
      onCloseModal()
    }
  }, [onCloseModal, uploading])

  const handleImport = useCallback(() => {
    if (uploading) {
      return
    }

    setUploading(true)
    reportSchedulerPaceService
      .addScheduleReport({ ...uploaded, type: ReportRequestType.INV_RCV_IMPORT.code })
      .then((response) => {
        if (validator.isObject(response) && validator.isId(response.id)) {
          closeModal()
          confirm({
            title: 'Import Received Amount Successfully',
            content: (
              <div>
                <p>Import Received Amount Request is made successfully. The process is scheduled and will be processed shortly.</p>
                <p>You could go to "Report Generate Request" section to view the progress.</p>
              </div>
            ),
            okText: 'Go to Report Request Page',
            cancelText: 'Back',
            onOk: () => {
              if (window) {
                const newTab = window.open('/reports-pace/custom-generate', '_blank', 'noopener,noreferrer')

                if (newTab) {
                  newTab.opener = null
                }
              }
            }
          })
        }
      })
      .catch((e) => {
        notify.error('Unable to import', 'Unable to import receive amount successfully. Please try again later.')
        console.log(e)
      })
      .finally(() => {
        setUploaded(false)
        setUploading(false)
        setUploadList([])
      })
  }, [closeModal, uploaded, uploading])

  const removeFile = useCallback((file) => {
    const idx = uploadList.indexOf(file)

    if (idx > -1) {
      const newList = uploadList.slice()
      newList.shift()
      setUploadList(newList)
    }
  }, [uploadList])

  return (
    <Modal
      className='ip-upload-modal'
      visible={showModal}
      title='Import Invoice Received Amount'
      onCancel={closeModal}
      footer={[
        <Button
          key='rcvclose'
          feedback={uploading}
          ghost
          onClick={closeModal}
        >
          Close
        </Button>,
        <Button
          key='rcvconfirm'
          disabled={!Array.isArray(uploadList) || uploadList.length < 1}
          feedback={uploading}
          ghost
          onClick={handleImport}
          style={{ backgroundColor: '#3d34eb', color: '#FFF' }}
        >
          Confirm
        </Button>
      ]}
    >
      <div>Upload the specified file for the request.</div>

      <div className='alert ip-margin-bottom'>{ReportRequestType.INV_RCV_IMPORT.uploadText}</div>

      <Upload
        action={`${apiHostname}/private/api/report-pace/scheduler/file`}
        headers={{ Authorization: `Bearer ${auth.getCurrentToken()}` }}
        method='POST'
        disabled={uploading}
        fileList={uploadList}
        multiple={false}
        name='file'
        beforeUpload={checkUpload}
        onChange={changeFile}
        onRemove={removeFile}
      >
        <Button className='btn-upload' feedback={uploading}><Icon type="upload" /> Select File</Button>

        {uploadMessage ? <div className='alert'>{uploadMessage}</div> : null}
      </Upload>
    </Modal>
  )
}

function ToReceiveList ({ bottomCols, bottomRows, topCols, topRows }) {
  const rows = getToReceiveListData(bottomRows, topRows)
  return (
    <Form>
      {Array.isArray(rows) ? rows.map((row, idx) => {
        const { id, invoice_date: invoiceDate, items } = row
        return (
          <div key={idx} className='ip-to-receive-list'>
            <table className='table-header'>
              <thead>
                <tr>
                  {Array.isArray(topCols) ? topCols.map((col, idx) => {
                    const { title, width } = col
                    return (
                      <td key={idx} className='col-header' style={{ ...getToReceiveColumnWidth(width) }}>
                        {title}
                      </td>
                    )
                  }) : null}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {Array.isArray(topCols) ? topCols.map((col, idx) => {
                    const { width, additional } = col
                    return (
                      <td key={idx} className='col-body' style={{ ...getToReceiveColumnWidth(width) }}>
                        {col.render
                          ? col.render(row, idx, additional)
                          : col.key
                            ? row[col.key]
                            : null}
                      </td>
                    )
                  }) : null}
                </tr>
              </tbody>
            </table>

            {Array.isArray(items) && items.length > 0 ? (
              <table className='table-body'>
                <thead>
                  <tr>
                    {Array.isArray(bottomCols) ? bottomCols.map((col, idx) => {
                      const { title, width } = col
                      return (
                        <td key={idx} className='col-header' style={{ ...getToReceiveColumnWidth(width) }}>
                          {title}
                        </td>
                      )
                    }) : null}
                  </tr>
                </thead>
                <tbody>
                  {items.map((row, rIdx) => (
                    <tr key={rIdx}>
                      {Array.isArray(bottomCols) ? bottomCols.map((col, cIdx) => {
                        const { width, additional } = col
                        return (
                          <td key={cIdx} className='col-body' style={{ ...getToReceiveColumnWidth(width) }}>
                            {col.render
                              ? col.render(row, rIdx, { ...additional, idx, invoice_id: id, invoice_date: invoiceDate })
                              : col.key
                                ? row[col.key]
                                : null}
                          </td>
                        )
                      }) : null}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : null}
          </div>
        )
      }) : null}
    </Form>
  )
}

function ToInvoiceList ({ cols, rows }) {
  return (
    <div className='ip-to-invoice-list'>
      <table className='table-invoice'>
        <thead>
          <tr>
            {Array.isArray(cols) ? cols.map((col, idx) => {
              const { title, width } = col
              return (
                <td key={idx} className='col-header' style={{ ...getToReceiveColumnWidth(width) }}>
                  {title}
                </td>
              )
            }) : null}
          </tr>
        </thead>
        <tbody>
          {Array.isArray(rows) ? rows.map((row, ridx) => (
            <Fragment key={ridx}>
              <tr key={`gap-${ridx}`}>
                {Array.isArray(cols) ? cols.map((col, cidx) => <td key={cidx} className='col-gap' />) : null}
              </tr>

              <tr key={`col-${ridx}`}>
                {Array.isArray(cols) ? cols.map((col, cidx) => {
                  const { width, additional } = col
                  return (
                    <td key={cidx} className='col-body' style={{ ...getToReceiveColumnWidth(width) }}>
                      {col.render
                        ? col.render(row, ridx, additional)
                        : col.key
                          ? row[col.key]
                          : null}
                    </td>
                  )
                }) : null}
              </tr>
            </Fragment>
          )) : null}
        </tbody>
      </table>
    </div>
  )
}

function InvoiceList (props) {
  const { form, history, location, match, selectedInvoiceIds } = props || {}
  const { validateFields, resetFields } = form
  const { search } = location || {}
  const { params } = match || {}
  const { type } = params || {}
  const qs = new URLSearchParams(search)
  const qsPage = qs.get('page')
  const qsStatus = qs.get('status')
  const qsSearchText = qs.get('st')
  const defaultPage = BigNumber(qsPage).isNaN() || !BigNumber(qsPage).isFinite() ? 1 : BigNumber(qsPage).toNumber()
  const defaultStatus = validator.isNullOrUndefined(qsStatus) || validator.isEmptyString(qsStatus) ? undefined : qsStatus
  const defaultSearchText = validator.isNullOrUndefined(qsStatus) || validator.isEmptyString(qsStatus) ? undefined : qsSearchText
  const [allChecked, setAllChecked] = useState(false)
  const [bulkUpdateAction, setBulkUpdateAction] = useState()
  const [bulkUpdateList, setBulkUpdateList] = useState([])
  const [clients, setClients] = useState([])
  const [filter, setFilter] = useState({
    invoice_type: getListFilterInvoiceType(type), status: getListFilterStatus(defaultStatus, type)
  })
  const [init, setInit] = useState(true)
  const [items, setItems] = useState([])
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(true)
  const [overallSummary, setOverallSummary] = useState({})
  const [page, setPage] = useState(defaultPage)
  const [providers, setProviders] = useState([])
  const [searchText, setSearchText] = useState(defaultSearchText)
  const [searching, setSearching] = useState(false)
  const [selectedClient, setSelectedClient] = useState()
  const [selectedInvoiceDate, setSelectedInvoiceDate] = useState()
  const [selectedProvider, setSelectedProvider] = useState()
  const [selectedStatus, setSelectedStatus] = useState(defaultStatus)
  const [selectedStatusDate, setSelectedStatusDate] = useState()
  const [showBulkUpdateModal, setShowBulkUpdateModal] = useState(false)
  const [showExportModal, setShowExportModal] = useState(false)
  const [showToReceiveImportModal, setShowToReceiveImportModal] = useState(false)
  const [sorting, setSorting] = useState(getListSorting(defaultStatus))
  const [statusListCache, setStatusListCache] = useState([DefaultStatus])
  const [summary, setSummary] = useState({})
  const [total, setTotal] = useState(0)
  const [updating, setUpdating] = useState(false)
  const isShowCheckboxAll = [
    InvoiceStatus.ToAuthorise.value, InvoiceStatus.ToClaim.value, InvoiceStatus.ToPay.value, InvoiceStatus.Drafted.value
  ].indexOf(selectedStatus) > -1
  const isToReceive = selectedStatus === InvoiceStatus.ToReceive.value
  const selectedList = Array.from(selectedInvoiceIds.values())

  const closeBulkUpdateModal = useCallback(() => {
    setBulkUpdateAction()
    setBulkUpdateList([])
    setShowBulkUpdateModal(false)
  }, [])

  const handleBulkUpdateSuccess = useCallback((callback, action, updatedList) => {
    const invoiceType = getListFilterInvoiceType(type)
    setLoading(true)

    Promise.all([
      action === InvoiceUpdateType.INV_UPDATE_DELETE
        ? invoicePaceService.listByPage(1, defaultPageSize, filter, sorting, searchText) : undefined,
      invoicePaceService.getSummary({ invoice_type: invoiceType })
    ])
      .then(([response, summaryResponse]) => {
        if (validator.isObject(response)) {
          const { items, list, summary, total } = response

          if (Array.isArray(items)) {
            setItems(items)
          }

          setList(list)

          if (validator.isObject(summary)) {
            setSummary(summary)
          }

          setTotal(total)
        } else {
          setList((list) => {
            const updatedLookup = new Map(updatedList.map((item) => [item.id, item]))
            return list.map((item) => {
              const updatedItem = updatedLookup.get(item.id)

              if (validator.isObject(updatedItem) && validator.isId(updatedItem.id)) {
                const { status, items_closed: itemsClosed } = updatedItem

                if (!validator.isNullOrUndefined(status)) {
                  item.status = status
                }

                if (Array.isArray(itemsClosed)) {
                  const itemClosedLookup = new Map(itemsClosed.map((invoiceItemId) => [invoiceItemId, invoiceItemId]))
                  const updatedItems = item.items.map((item) => {
                    const invoiceItemId = itemClosedLookup.get(item.id)

                    if (validator.isId(invoiceItemId)) {
                      item.checked = false
                      item.closed = true
                    }

                    return item
                  })
                  item.items = updatedItems
                  item.checked = updatedItems.some(({ checked }) => checked === true)
                }
              }

              return item
            })
          })
        }

        if (validator.isObject(summaryResponse)) {
          setOverallSummary(summaryResponse)
        }
      })
      .finally(() => {
        closeBulkUpdateModal()
        setAllChecked(false)
        setLoading(false)

        if (typeof callback === 'function') {
          callback()
        }
      })
  }, [closeBulkUpdateModal, filter, searchText, sorting, type])

  const listByPage = useCallback(({ page, filter: newFilter, text }) => {
    const _filter = { ...filter, ...newFilter }
    const _page = typeof page === 'number' && page > 0 ? page : 1
    const _text = text || searchText
    setAllChecked(false)
    setFilter(_filter)
    setLoading(true)
    setPage(_page)
    invoicePaceService.listByPage(_page, defaultPageSize, _filter, sorting, _text)
      .then((response) => {
        if (validator.isObject(response)) {
          const { items, list, summary, total } = response

          if (Array.isArray(items)) {
            setItems(items)
          }

          setList(list.map((item) => {
            const _item = selectedInvoiceIds.get(item.id)
            item.checked = validator.isObject(_item) && validator.isId(_item.id)
            return item
          }))

          if (validator.isObject(summary)) {
            setSummary(summary)
          }

          setTotal(total)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [filter, searchText, selectedInvoiceIds, sorting])

  const resetFilterInputs = useCallback(() => {
    setSelectedClient(undefined)
    setSelectedInvoiceDate(undefined)
    setSelectedProvider(undefined)
    setSelectedStatusDate(undefined)
  }, [])

  const changeCheckbox = useCallback((idx) => {
    let checkCount = 0
    const checkedList = list.map((_item, _idx) => {
      if (_idx === idx) {
        const { id, checked } = _item
        _item.checked = checked

        if (checked) {
          checkCount += 1
          selectedInvoiceIds.set(id, _item)
        } else {
          selectedInvoiceIds.delete(id)
        }
      }

      return _item
    })
    setAllChecked(checkCount === list.length)
    setList(checkedList)
  }, [list, selectedInvoiceIds])

  const changeAllCheckboxes = useCallback(({ target }) => {
    const { checked } = target
    const checkedList = list.map((item) => {
      const { id, items } = item
      item.checked = checked

      if (Array.isArray(item.items)) {
        item.items = items.map((iItem) => {
          iItem.checked = checked
          return iItem
        })
      }

      if (checked) {
        selectedInvoiceIds.set(id, item)
      } else {
        selectedInvoiceIds.delete(id)
      }

      return item
    })
    setAllChecked(checked)
    setList(checkedList)
  }, [list, selectedInvoiceIds])

  const changeClient = useCallback((clientId) => {
    setSelectedClient(clientId)
    listByPage({ page: 1, filter: { client_id: clientId } })
  }, [listByPage])

  const changeInvoiceDate = useCallback((invoiceDate) => {
    const filter = { invoice_date: undefined }

    if (!validator.isNullOrUndefined(invoiceDate)) {
      filter.invoice_date = {
        $and: [
          { condition: '>=', value: moment(invoiceDate).startOf('day').toISOString() },
          { condition: '<=', value: moment(invoiceDate).endOf('day').toISOString() }
        ]
      }
    }

    setSelectedInvoiceDate(invoiceDate)
    listByPage({ page: 1, filter })
  }, [listByPage])

  const changeItemCheckbox = useCallback((idx, iIdx, item, callback) => {
    const listItem = list[idx]

    if (listItem && Array.isArray(listItem.items)) {
      const { id } = listItem
      const { checked } = item
      list[idx].items[iIdx] = item

      if (checked) {
        list[idx].checked = checked
        selectedInvoiceIds.set(id, list[idx])
      }

      const isAnyItemChecked = list[idx].items.some(({ checked }) => checked === true)

      if (!isAnyItemChecked) {
        list[idx].checked = false
        selectedInvoiceIds.delete(id)
      }

      const isAllChecked = list.every(({ checked }) => checked === true)
      setAllChecked(isAllChecked)
      setList([...list])

      if (typeof callback === 'function') {
        callback()
      }
    }
  }, [list, selectedInvoiceIds])

  const changePage = useCallback((page) => {
    listByPage({ page })
  }, [listByPage])

  const changeProvider = useCallback((providerId) => {
    setSelectedProvider(providerId)
    listByPage({ page: 1, filter: { provider_id: providerId } })
  }, [listByPage])

  const changeStatus = useCallback((value) => {
    if (value !== selectedStatus) {
      qs.set('page', 1)
      qs.set('status', value)

      if (validator.isNullOrUndefined(searchText) || validator.isEmptyString(searchText)) {
        qs.delete('st')
      } else {
        qs.set('st', searchText)
      }

      setAllChecked(false)
      setFilter({ ...filter, status: getListFilterStatus(value, type) })
      setPage(1)
      setSelectedStatus(value)
      setSorting(getListSorting(value))
      resetFilterInputs()
      selectedInvoiceIds.clear()
      history.replace({ pathname: location.pathname, search: qs.toString() })
    }
  }, [resetFilterInputs, filter, history, location, qs, searchText, selectedInvoiceIds, selectedStatus, type])

  const changeStatusDate = useCallback((statusDate) => {
    const isStatusDate = [
      InvoiceStatus.ToClaim.value, InvoiceStatus.ToReceive.value, InvoiceStatus.ToPay.value
    ].indexOf(selectedStatus) > -1
    const key = [isStatusDate ? 'status_date' : 'processed_at']
    const filter = { [key]: undefined }

    if (!validator.isNullOrUndefined(statusDate)) {
      filter[key] = {
        $and: [
          { condition: '>=', value: moment(statusDate).startOf('day').toISOString() },
          { condition: '<=', value: moment(statusDate).endOf('day').toISOString() }
        ]
      }
    }

    setSelectedStatusDate(statusDate)
    listByPage({ page: 1, filter })
  }, [listByPage, selectedStatus])

  const closeExportModal = useCallback(() => {
    setShowExportModal(false)
  }, [])

  const closeToReceiveImportModal = useCallback(() => {
    setShowToReceiveImportModal(false)
  }, [])

  const filterClient = useCallback((input, option) => {
    const { props } = option || {}
    const { children } = props || []
    const str = Array.isArray(children) ? children.join(' ') : `${children}`
    return str.toLowerCase().indexOf(input.toLowerCase()) > -1
  }, [])

  const filterProvider = useCallback((input, option) => {
    const { props } = option || {}
    const { children } = props || []
    const str = Array.isArray(children) ? children.join(' ') : `${children}`
    return str.toLowerCase().indexOf(input.toLowerCase()) > -1
  }, [])

  const getTitle = useCallback((type) => {
    let title = ''

    if (type === InvoiceListType.INV_LIST_PM) {
      title = 'Provider '
    } else if (type === InvoiceListType.INV_LIST_STD) {
      title = 'PM Fee '
    }

    return `${title}Invoices`
  }, [])

  const getStatusDateLabel = useCallback(() => {
    if (selectedStatus === InvoiceStatus.ToClaim.value) {
      return "Auth'd Date"
    } else if (selectedStatus === InvoiceStatus.ToReceive.value) {
      return 'Claimed Date'
    } else if (selectedStatus === InvoiceStatus.ToPay.value) {
      return 'Received Date'
    }

    return 'Process Date'
  }, [selectedStatus])

  const getStatusList = useCallback((statusList, type) => {
    let list = (Array.isArray(statusList) ? statusList : [])
      .filter(({ reference_2: ref2 }) => typeof ref2 === 'string' && ref2.indexOf('opts') > -1)

    if (type === InvoiceListType.INV_LIST_PM) {
      list = list.filter(({ reference_2: ref2 }) => ref2.indexOf('_pm') > -1)
    } else if (type === InvoiceListType.INV_LIST_STD) {
      list = list.filter(({ reference_2: ref2 }) => ref2.indexOf('_std') > -1)
    }

    return list.concat(DefaultStatus)
  }, [])

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  const handleUpdate = useCallback(async (action, selectedList) => {
    if (updating) {
      return
    }

    const errorTitle = `Unable to ${action}`

    if (
      [
        InvoiceUpdateType.INV_UPDATE_ABA_RMT, InvoiceUpdateType.INV_UPDATE_AUTHORISED, InvoiceUpdateType.INV_UPDATE_DELETE,
        InvoiceUpdateType.INV_UPDATE_PYMT_REQUEST, InvoiceUpdateType.INV_UPDATE_RCV_PYMT, InvoiceUpdateType.INV_UPDATE_REJECTED,
      ].indexOf(action) < 0 || selectedList.length < 1
    ) {
      notify.error(errorTitle, messageInvalidBulkUpdateAction)
    }

    const values = { invoices: selectedList.map(({ id, items }) => ({ id, items })) }
    let updateError
    let updateAction

    if (action === InvoiceUpdateType.INV_UPDATE_ABA_RMT) {
      updateAction = 'pay'

      try {
        const result = await validateFields(['payment_date'])
        values.payment_date = result.payment_date.toISOString()
      } catch (e) {
        updateError = e
      }
    } else if (action === InvoiceUpdateType.INV_UPDATE_AUTHORISED) {
      updateAction = 'authorise'
    } else if (action === InvoiceUpdateType.INV_UPDATE_PYMT_REQUEST) {
      updateAction = 'claim'
    }

    if (updateError) {
      return
    }

    setUpdating(true)
    invoiceBulkPaceService
      .bulkUpdate(updateAction, values)
      .then((response) => {
        if (validator.isObject(response)) {
          // const { count, invoices, partial, success, updated } = response
          const { success, file, updated } = response

          if (success === true) {
            notify.success(`Updated Successfully`, `Invoice(s) updated successfully.`)
            handleBulkUpdateSuccess(() => { setUpdating(false) }, action, updated)

            if (action === InvoiceUpdateType.INV_UPDATE_RCV_PYMT) {
              resetFields()
            }

            if (file && validator.isId(file.id)) {
              if (file.type === ExportType.TYPE_PAYMENT) {
                file.type = ExportType.TYPE_ABA
              }

              exportFilePace.fetchFile(file)
            }
          } else {
            notify.error(errorTitle, `Unable to ${action} successfully. Please try again later.`)
            setUpdating(false)
          }
        }
      })
      .catch((e) => {
        notify.error(errorTitle, `Unable to ${action} successfully. Please try again later.`)
        console.log(e)
        setUpdating(false)
      })
  }, [handleBulkUpdateSuccess, resetFields, validateFields, updating])

  const handleBulkAction = useCallback((action) => () => {
    if (selectedList.length > 0) {
      if (action === InvoiceUpdateType.INV_UPDATE_AUTHORISED || action === InvoiceUpdateType.INV_UPDATE_PYMT_REQUEST) {
        setBulkUpdateAction(action)
        setBulkUpdateList(selectedList)
        handleUpdate(action, selectedList)
      } else {
        notify.error(`Unable to ${action}`, messageInvalidBulkUpdateAction)
      }
    } else {
      notify.error(`Unable to ${action}`, messageAtLeastOneInvoice)
      validateFields(['invoices'], { force: true })
    }
  }, [handleUpdate, validateFields, selectedList])

  const openBulkUpdateModal = useCallback((action) => () => {
    const isUpdateRcvPymt = action === InvoiceUpdateType.INV_UPDATE_RCV_PYMT
    const openModal = (selectedList) => {
      setBulkUpdateAction(action)
      setBulkUpdateList(selectedList)
      setShowBulkUpdateModal(true)
    }

    if (selectedList.length > 0) {
      if (isUpdateRcvPymt) {
        validateFields(['invoices'], { force: true }, (errors, values) => {
          if (errors) {
            notify.error(`Unable to ${action}`, 'Please check all receive amount and receive date entered.')
            return
          }

          const { invoices } = values
          const changeCount = invoices.reduce((accumulator, current) => {
            const { items } = current
            const hasChanges = items.some(({ received_amount: amount, received_date: date, closed }) => (
              !validator.isNullOrUndefined(amount) || !validator.isNullOrUndefined(date) || closed === true
            ))
            return accumulator + (hasChanges ? 1 : 0)
          }, 0)

          if (changeCount > 0) {
            openModal(list)
          } else {
            notify.error(`Unable to ${action}`, 'Please enter receive amount and receive date.')
          }
        })
      } else {
        if (action === InvoiceUpdateType.INV_UPDATE_AUTHORISED || action === InvoiceUpdateType.INV_UPDATE_PYMT_REQUEST) {
          notify.error(`Unable to ${action}`, messageAtLeastOneInvoice)
        } else {
          openModal(selectedList)
        }
      }
    } else {
      notify.error(`Unable to ${action}`, messageAtLeastOneInvoice)
      validateFields(['invoices'], { force: true })
    }
  }, [validateFields, list, selectedList])

  const openRejectModal = useCallback((action, item) => () => {
    const selectedList = list.filter(({ id }) => id === item.id)

    if (selectedList.length > 0) {
      setBulkUpdateAction(action)
      setBulkUpdateList(selectedList)
      setShowBulkUpdateModal(true)
    } else {
      notify.error(`Unable to ${action}`, messageAtLeastOneInvoice)
    }
  }, [list])

  const openExportModal = useCallback(() => {
    setShowExportModal(true)
  }, [])

  const openToReceiveImportModal = useCallback(() => {
    setShowToReceiveImportModal(true)
  }, [])

  const searchInvoice = useCallback(() => {
    const onSearchInvoice = async (value) => {
      setLoading(true)
      setPage(1)
      setSearchText(value)
      setSearching(true)

      try {
        const response = await invoicePaceService.listByPage(1, defaultPageSize, filter, sorting, value)

        if (validator.isObject(response)) {
          const { items, list, summary, total } = response
          if (Array.isArray(items)) {
            setItems(items)
          }

          setList(list)

          if (validator.isObject(summary)) {
            setSummary(summary)
          }

          setTotal(total)
        }
      } finally {
        setLoading(false)
        setSearching(false)
      }
    }
    return debounce(onSearchInvoice, 500)
  }, [filter, sorting])

  const toggleItemCommentVisible = useCallback((idx, iIdx, item) => {
    const listItem = list[idx]

    if (listItem && Array.isArray(listItem.items)) {
      list[idx].items[iIdx] = item
      setList([...list])
    }
  }, [list])

  useEffect(() => {
    if (!hasAccess(Permissions.INVOICE.INFO_PACE.LIST)) {
      return
    }

    const invoiceType = getListFilterInvoiceType(type)
    const status = getListFilterStatus(defaultStatus, type)
    const filter = { invoice_type: invoiceType, status }
    const sorting = getListSorting(defaultStatus)
    let mounted = true
    setLoading(true)
    Promise.all([
      invoicePaceService.listByPage(1, defaultPageSize, filter, sorting, defaultSearchText),
      invoicePaceService.getSummary({ invoice_type: invoiceType }),
      clientService.getAllDropdowns({ active: true }),
      providerService.getAllDropdowns({ active: true })
    ])
      .then(([response, summaryResponse, clientResponse, providerResponse]) => {
        if (mounted) {
          if (validator.isObject(response)) {
            const { items, list, summary, total } = response
            if (Array.isArray(items)) {
              setItems(items)
            }

            const updatedList = list.map(item => ({
              ...item,
              checked: false,
            }));

            setList(updatedList)

            if (validator.isObject(summary)) {
              setSummary(summary)
            }

            setTotal(total)
          }

          if (validator.isObject(summaryResponse)) {
            setOverallSummary(summaryResponse)
          }

          if (Array.isArray(clientResponse)) {
            setClients(clientResponse)
          }

          if (Array.isArray(providerResponse)) {
            setProviders(providerResponse)
          }
        }
      })
      .finally(() => {
        if (mounted) {
          setInit(false)
          setLoading(false)
        }
      })

    return () => {
      mounted = false
    }
  }, [hasAccess, defaultStatus, defaultSearchText, type])

  useEffect(() => {
    if (!hasAccess(Permissions.INVOICE.INFO_PACE.LIST)) {
      return
    }

    let mounted = true
    invoiceListService.getAllStatus('options')
      .then((statusList) => {
        if (mounted && Array.isArray(statusList)) {
          const statusListCache = statusList.filter(({ reference_2: ref2 }) => typeof ref2 === 'string' && ref2.indexOf('opts') > -1)
          setStatusListCache(statusListCache)
        }
      })

    return () => {
      mounted = false
    }
  }, [hasAccess])

  useEffect(() => {
    if (Array.isArray(statusListCache) && statusListCache.length > 1) {
      const statusList = getStatusList(statusListCache, type)
      setSelectedStatus(defaultStatus || statusList[0].value)
    }
  }, [getStatusList, defaultStatus, statusListCache, type])

  useEffect(() => {
    const allChecked = list.length > 0 && list.every(({ checked }) => checked === true)
    setAllChecked(allChecked)
  }, [list])

  return (
    <Page.Body>
      <Page.Left>
        <Page.Menu title='Home' menu={InvoicePaceMenu} backLink='/' />
      </Page.Left>

      <Page.Content full>
        <Page.Header title={getTitle(type)}>
          {hasAccess(Permissions.INVOICE.INFO_PACE.LIST)
            ? (
              <Button onClick={openExportModal}>
                Export
              </Button>
            ) : null}

          {hasAccess(Permissions.INVOICE.INFO_PACE.CREATE)
            ? (
              <Link to={`${urlRedirect}/add`}>
                <Button>
                  New Invoice
                </Button>
              </Link>
            ) : null}
        </Page.Header>

        <Skeleton loading={init} active>
          <div className='ip-invoice-list'>
            <InvoiceSummary
              loading={loading} selectedStatus={selectedStatus} summary={overallSummary} type={type} onChangeStatus={changeStatus}
            />

            <Row className='ip-margin-bottom' gutter={16}>
              <Col lg={6}>
                <ControlLabel>Participant</ControlLabel>

                <Select
                  allowClear
                  disabled={loading}
                  dropdownMatchSelectWidth={false}
                  filterOption={filterClient}
                  optionFilterProp='children'
                  placeholder='Select Participant'
                  showSearch
                  value={selectedClient}
                  onChange={changeClient}
                  style={{ width: '100%' }}
                >
                  {clients.map(({ id, first_name: firstName, last_name: lastName, active }) => (
                    <Option key={id} disabled={!active} value={id}>{firstName} {lastName}</Option>
                  ))}
                </Select>
              </Col>

              <Col lg={6}>
                <ControlLabel>Provider</ControlLabel>

                <Select
                  allowClear
                  disabled={loading}
                  dropdownMatchSelectWidth={false}
                  filterOption={filterProvider}
                  optionFilterProp='children'
                  placeholder='Select Provider'
                  showSearch
                  value={selectedProvider}
                  onChange={changeProvider}
                  style={{ width: '100%' }}
                >
                  {providers.map(({ id, fullname, active }) => (
                    <Option key={id} disabled={!active} value={id}>{fullname}</Option>
                  ))}
                </Select>
              </Col>

              <Col lg={12}>
                <ControlLabel>&nbsp;</ControlLabel>

                <div className='ip-text-right'>
                  {hasAccess(Permissions.INVOICE.INFO_PACE.UPDATE) && selectedStatus === InvoiceStatus.ToAuthorise.value ? (
                    <Button
                      feedback={updating}
                      onClick={handleBulkAction(InvoiceUpdateType.INV_UPDATE_AUTHORISED)}
                      style={{ backgroundColor: '#00ff00', color: '#1d2c47' }}
                    >
                      Approve
                    </Button>
                  ) : null}

                  {hasAccess(Permissions.INVOICE.INFO_PACE.LIST) && selectedStatus === InvoiceStatus.ToClaim.value ? (
                    <Button
                      feedback={updating}
                      onClick={handleBulkAction(InvoiceUpdateType.INV_UPDATE_PYMT_REQUEST)}
                      style={{ backgroundColor: '#274faa' }}
                    >
                      Generate Pymt Req
                    </Button>
                  ) : null}

                  {selectedStatus === InvoiceStatus.ToReceive.value ? (
                    <>
                      {hasAccess(Permissions.REPORT.SCHEDULER.LIST) && hasAccess(Permissions.REPORT.SCHEDULER.CREATE)
                        ? <Button feedback={updating} onClick={openToReceiveImportModal} style={{ backgroundColor: '#1d2c47' }}>
                          Import PYMT Summary
                        </Button>
                        : null}

                      {hasAccess(Permissions.INVOICE.INFO_PACE.UPDATE) ? (
                        <Button
                          feedback={updating}
                          onClick={openBulkUpdateModal(InvoiceUpdateType.INV_UPDATE_RCV_PYMT)}
                          style={{ backgroundColor: '#eb7a34' }}
                        >
                          Update Payment
                        </Button>
                      ) : null}
                    </>
                  ) : null}

                  {hasAccess(Permissions.INVOICE.INFO_PACE.LIST) && selectedStatus === InvoiceStatus.ToPay.value ? (
                    <Button
                      feedback={updating}
                      onClick={openBulkUpdateModal(InvoiceUpdateType.INV_UPDATE_ABA_RMT)}
                      style={{ backgroundColor: '#0033cc' }}
                    >
                      Generate ABA/REMIT
                    </Button>
                  ) : null}

                  {hasAccess(Permissions.INVOICE.INFO_PACE.DELETE) && selectedStatus === InvoiceStatus.Drafted.value ? (
                    <Button feedback={updating} onClick={openBulkUpdateModal(InvoiceUpdateType.INV_UPDATE_DELETE)}>
                      Delete
                    </Button>
                  ) : null}
                </div>
              </Col>
            </Row>

            <Row className='ip-margin-bottom' gutter={16}>
              <Col lg={6}>
                <ControlLabel>{getStatusDateLabel()}</ControlLabel>

                <DatePicker
                  allowClear
                  disabled={loading}
                  format={dateFormat}
                  placeholder={getStatusDateLabel()}
                  value={selectedStatusDate}
                  onChange={changeStatusDate}
                  style={{ width: '100%' }}
                />
              </Col>

              <Col lg={6}>
                <ControlLabel>Invoice Date</ControlLabel>

                <DatePicker
                  allowClear
                  disabled={loading}
                  format={dateFormat}
                  placeholder='Invoice Date'
                  value={selectedInvoiceDate}
                  onChange={changeInvoiceDate}
                  style={{ width: '100%' }}
                />
              </Col>

              <Col lg={6}>
                <ControlLabel>Invoice # / JID # / Participant Info</ControlLabel>

                <SearchInput
                  isSearching={searching}
                  placeholder='Enter Invoice # / JID # / Participant Info'
                  value={searchText}
                  onChange={searchInvoice()}
                />
              </Col>

              <Col lg={6} >
                {isShowCheckboxAll ? (
                  <div className='ip-flex-box'>
                    <span className='ip-checkbox-label'>
                      Select / Deselect All ({selectedInvoiceIds.size} item{selectedInvoiceIds.size === 1 ? '' : 's'} selected)
                    </span>
                    <Checkbox checked={allChecked} onChange={changeAllCheckboxes} />
                  </div>
                ) : null}
              </Col>
            </Row>

            <InvoiceListSummary loading={loading} summary={summary} />

            <Spin spinning={loading}>
              {isToReceive
                ? (
                  <ToReceiveList
                    bottomCols={getToReceiveBottomColumns(props, changeItemCheckbox, toggleItemCommentVisible)}
                    bottomRows={items}
                    topCols={getToReceiveTopColumns(hasAccess, selectedStatus, openRejectModal)}
                    topRows={list}
                  />
                )
                : <ToInvoiceList cols={getInvoiceColumns(hasAccess, selectedStatus, changeCheckbox, openRejectModal)} rows={list} />}

              <Pager
                current={page}
                size={defaultPageSize}
                total={total}
                totalText={`Total ${total} invoices`}
                onChange={changePage}
                onChangePrompt={isToReceive && list.some(({ checked }) => checked === true).length > 0}
                onChangePromptInfo={onChangePromptInfo}
                style={{ marginTop: '15px' }}
              />
            </Spin>

            <InvoiceBulkUpdateModal
              action={bulkUpdateAction} list={bulkUpdateList} props={props} showModal={showBulkUpdateModal}
              onCloseModal={closeBulkUpdateModal} onUpdateSuccess={handleBulkUpdateSuccess}
            />

            <InvoiceExportModal showModal={showExportModal} onCloseModal={closeExportModal} />

            <ToReceiveImportModal showModal={showToReceiveImportModal} onCloseModal={closeToReceiveImportModal} />
          </div>
        </Skeleton>
      </Page.Content>
    </Page.Body>
  )
}

export class InvoiceListPace extends Component {
  render () {
    return <InvoiceList {...this.props} />
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return { ...state.InvoicePace }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(InvoiceListPace))
