import { request, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/participants/plans-pace'

export default {
  add (values) {
    return request(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  checkRoleStartDate (clientId) {
    return request(`${url}/recent/role-start-date${queryString.stringify({ clientId })}`)
  },
  checkDates (startDate, endDate, id, clientId) {
    return request(`${url}/check/dates${queryString.stringify({ id, start_date: startDate, end_date: endDate, client_id: clientId })}`)
  },
  get (id) {
    return request(`${url}/${id}`)
  },
  getAllList (filter) {
    return request(`${url}/list${queryString.stringify({ filter: JSON.stringify(filter) })}`)
  },
  listByPage (page, size, filter, sorting, text) {
    return request(`${url}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  remove (id) {
    return request(`${url}/${id}`, {
      method: 'DELETE',
      headers
    })
  },
  save (id, values) {
    return request(`${url}/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  }
}
