import React, { Component, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import BigNumber from 'bignumber.js'
import clsx from 'clsx'
import moment from 'moment-timezone'

import { Permissions } from '../../../../constants'
import { clientPlanPaceService } from '../../../../services'
import { auth, formatter, validator } from '../../../../util'

// UI
import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Tooltip from 'antd/lib/tooltip'

import { List, Pager } from '../../../../components'
import notify from '../../../../components/Notification'

import './styles.css'

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const { confirm, info } = Modal

const defaultPageSize = 20

function safePlus (total, amount) {
  return BigNumber(BigNumber(total).isNaN() ? 0 : total).plus(BigNumber(amount).isNaN() ? 0 : amount).toFixed(2)
}

function sortListItem ({ aIsPmFee, aCategorySorting, aItemSorting }, { bIsPmFee, bCategorySorting, bItemSorting }) {
  // Moves category number '14' to the top
  if (aIsPmFee !== bIsPmFee) {
    return aIsPmFee ? -1 : 1
  }

  if (aCategorySorting !== bCategorySorting) {
    return aCategorySorting - bCategorySorting
  }

  return aItemSorting - bItemSorting
}

function getClientPlanList (response) {
  const { client_funding_items: clientFundingItems, client_plan_items: clientPlanItems, list, total } = response

  if (Array.isArray(list) && list.length > 0) {
    const clientFundingLookup = new Map()
    const clientFundingSetMap = new Map()
    const clientFudingItemListMap = new Map()
    const clientPlanLookup = new Map(list.map((item) => [item.id, item]))
    const clientPlanItemListMap = new Map()

    for (const clientFudingItem of clientFundingItems) {
      const {
        id, plan_id: planId, cfi_id: cfiId, id_number: idNumber, start_date: startDate, end_date: endDate, is_pm_fee: isPmFee,
        active, visible, cfi_allocated_amount: cfiAllocatedAmount, total_remaining: cfiTotalRemaining,
        forecast_remaining: cfiForecastRemaining
      } = clientFudingItem
      const clientFunding = clientFundingLookup.get(id) || {
        id, plan_id: planId, cfi_id: cfiId, id_number: idNumber, start_date: startDate, end_date: endDate, is_pm_fee: isPmFee,
        active, visible
      }
      const {
        client_funding_items: cfClientFundingItems, total_allocated: totalAllocated, total_remaining: totalRemaining,
        total_forecasted: totalForecasted
      } = clientFunding
      const { active: cpActive } = clientPlanLookup.get(planId) || {}
      const cpIdx = list.findIndex(({ id }) => id === planId)
      let cfSet = clientFundingSetMap.get(planId)
      let cfiList = clientFudingItemListMap.get(cfiId)
      cfSet = cfSet instanceof Set ? cfSet : new Set()
      cfiList = Array.isArray(cfiList) ? cfiList : []
      cfiList.push(clientFudingItem)
      clientFunding.client_funding_items = (Array.isArray(cfClientFundingItems) ? cfClientFundingItems : []).concat(cfiList)
      clientFunding.total_allocated = safePlus(totalAllocated, cfiAllocatedAmount)
      clientFunding.total_remaining = safePlus(totalRemaining, cfiTotalRemaining)
      clientFunding.total_forecasted = safePlus(totalForecasted, cfiForecastRemaining)
      clientFunding.expand = cpActive && active && (cpIdx === 0 ? true : moment().isSameOrBefore(startDate) && moment().isSameOrAfter(endDate))
      cfSet.add(id)
      clientFundingLookup.set(id, clientFunding)
      clientFundingSetMap.set(planId, cfSet)
      clientFudingItemListMap.set(cfiId, cfiList)
    }

    for (const clientPlanItem of clientPlanItems) {
      const {
        id, cpi_allocated_amount: cpiAllocatedAmount, total_remaining: cpiTotalRemaining, forecast_remaining: cpiForecastRemaining
      } = clientPlanItem
      const clientPlan = clientPlanLookup.get(id) || {}
      const { total_allocated: totalAllocated, total_remaining: totalRemaining, total_forecasted: totalForecasted } = clientPlan
      let cpiList = clientPlanItemListMap.get(id)
      cpiList = Array.isArray(cpiList) ? cpiList : []
      cpiList.push(clientPlanItem)
      clientPlan.total_allocated = safePlus(totalAllocated, cpiAllocatedAmount)
      clientPlan.total_remaining = safePlus(totalRemaining, cpiTotalRemaining)
      clientPlan.total_forecasted = safePlus(totalForecasted, cpiForecastRemaining)
      clientPlanLookup.set(id, clientPlan)
      clientPlanItemListMap.set(id, cpiList)
    }

    for (let i = 0; i < list.length; i++) {
      const item = list[i]
      const { id, start_date: startDate, end_date: endDate, active } = item
      const cfSet = clientFundingSetMap.get(id)
      const cpiList = clientPlanItemListMap.get(id)
      const clientFundings = cfSet instanceof Set ? Array.from(cfSet).map((cfId) => clientFundingLookup.get(cfId)) : []
      list[i].client_fundings = clientFundings.map((clientFunding) => {
        clientFunding.client_funding_items.sort((a, b) => {
          const { cfi_category_sorting: aCategorySorting, cfi_item_sorting: aItemSorting, cfi_is_pm_fee: aIsPmFee } = a
          const { cfi_category_sorting: bCategorySorting, cfi_item_sorting: bItemSorting, cfi_is_pm_fee: bIsPmFee } = b
          return sortListItem({ aIsPmFee, aCategorySorting, aItemSorting }, { bIsPmFee, bCategorySorting, bItemSorting })
        })
        return clientFunding
      })
      const clientPlanItems = Array.isArray(cpiList) ? cpiList : []
      clientPlanItems.sort((a, b) => {
        const { cpi_category_sorting: aCategorySorting, cpi_item_sorting: aItemSorting, cpi_is_pm_fee: aIsPmFee } = a
        const { cpi_category_sorting: bCategorySorting, cpi_item_sorting: bItemSorting, cpi_is_pm_fee: bIsPmFee } = b
        return sortListItem({ aIsPmFee, aCategorySorting, aItemSorting }, { bIsPmFee, bCategorySorting, bItemSorting })
      })
      list[i].client_plan_items = clientPlanItems
      list[i].expand = active && (i === 0 ? true : moment().isSameOrBefore(startDate) && moment().isSameOrAfter(endDate))
      list[i].highlight = list[i].expand
    }
  }

  return { list, total }
}

function renderColumnSupportCategory (categoryNumber, categoryName) {
  return `${categoryNumber ? `(${categoryNumber.padStart(2, '0')}) ` : ''}${categoryName || ''}`
}

function renderColumnSupportItem (itemNumber, itemName) {
  return `${itemNumber ? `(${itemNumber}) ` : ''}${itemName || ''}`
}

function renderColumnAvailableBalance (forecast, total) {
  return `(${formatter.toPrice(forecast)}) / ${formatter.toPrice(total)}`
}

function renderColumnAllocatedAmount (allocatedAmount) {
  return formatter.toPrice(allocatedAmount)
}

function renderColumnSpent (forecast, total) {
  return `(${formatter.toPrice(forecast)}) / ${formatter.toPrice(total)}`
}

function getClientFundingColumns () {
  return Object.freeze([
    {
      title: 'Support Category',
      width: 6,
      render: ({
        cfi_category_number: categoryNumber, cfi_category_name: categoryName
      }) => renderColumnSupportCategory(categoryNumber, categoryName)
    },
    {
      title: 'Support Item',
      width: 6,
      render: ({ cfi_item_number: itemNumber, cfi_item_name: itemName }) => renderColumnSupportItem(itemNumber, itemName)
    },
    {
      title: '(Forecast) / Available Balance',
      width: 4,
      render: ({ total_remaining: total, forecast_remaining: forecast }) => renderColumnAvailableBalance(forecast, total)
    },
    {
      title: 'Allocated Amount',
      width: 4,
      render: ({ cfi_allocated_amount: allocatedAmount }) => renderColumnAllocatedAmount(allocatedAmount)
    },
    {
      title: '(Forecast) / Spent',
      width: 4,
      render: ({ total_spent: total, forecast_spent: forecast }) => renderColumnSpent(forecast, total)
    }
  ])
}

function getClientPlanColumns () {
  return Object.freeze([
    {
      title: 'Support Category',
      width: 6,
      render: ({
        cpi_category_number: categoryNumber, cpi_category_name: categoryName
      }) => renderColumnSupportCategory(categoryNumber, categoryName)
    },
    {
      title: 'Support Item',
      width: 6,
      render: ({ cpi_item_number: itemNumber, cpi_item_name: itemName }) => renderColumnSupportItem(itemNumber, itemName)
    },
    {
      title: '(Forecast) / Available Balance',
      width: 4,
      render: ({ total_remaining: total, forecast_remaining: forecast }) => renderColumnAvailableBalance(forecast, total)
    },
    {
      title: 'Allocated Amount',
      width: 4,
      render: ({ cpi_allocated_amount: allocatedAmount }) => renderColumnAllocatedAmount(allocatedAmount)
    },
    {
      title: '(Forecast) / Spent',
      width: 4,
      render: ({ total_spent: total, forecast_spent: forecast }) => renderColumnSpent(forecast, total)
    }
  ])
}

function PlanPeriodListBody ({ className, cols, rows, hasAccess, item, onDeleteItem, onEditItem, onToggleExpand }) {
  const {
    id, planId, startDate, endDate, roleStartDate, roleEndDate, totalAllocated, totalRemaining, totalForecasted, expand
  } = item

  const deleteItem = useCallback((id, item) => () => {
    if (typeof onDeleteItem === 'function') {
      onDeleteItem(id, item)
    }
  }, [onDeleteItem])

  const editItem = useCallback((id) => () => {
    if (typeof onEditItem === 'function') {
      onEditItem(id)
    }
  }, [onEditItem])

  const toggleExpand = useCallback((id, planId) => () => {
    if (typeof onToggleExpand === 'function') {
      onToggleExpand(id, planId)
    }
  }, [onToggleExpand])

  return (
    <div className={className}>
      <div className='header-box'>
        <div className='title-box'>
          <div className='label'>{formatter.toShortDate(startDate)} - {formatter.toShortDate(endDate)}</div>

          <Icon className='btn-icon' type={expand ? 'up-circle' : 'down-circle'} onClick={toggleExpand(id, planId)} />
        </div>

        <div className='button-box'>
          {typeof hasAccess === 'function' && hasAccess([Permissions.PARTICIPANT.PP_PACE.READ, Permissions.PARTICIPANT.PP_PACE.UPDATE])
            ? (
              <Tooltip mouseLeaveDelay={0} title='Edit Plan Period'>
                <Icon className='btn-icon' type='form' onClick={editItem(id)} />
              </Tooltip>
            )
            : null}

          {typeof hasAccess === 'function' && hasAccess(Permissions.PARTICIPANT.PP_PACE.DELETE)
            ? (
              <Tooltip mouseLeaveDelay={0} title='Delete Plan Period'>
                <Icon className='btn-icon' type='delete' onClick={deleteItem(id, item)} />
              </Tooltip>
            )
            : null}
        </div>
      </div>

      {roleStartDate ? (
        <div className='ppp-sub-title'>
          Role Start: {formatter.toShortDate(roleStartDate)}{roleEndDate ? ` - ${formatter.toShortDate(roleEndDate)}` : ''}
        </div>
      ) : null}

      <div>
        <div className='summary-box'>
          <div>Total Allocated: {formatter.toPrice(totalAllocated)}</div>
          <div>Total Remaining: {formatter.toPrice(totalRemaining)}</div>
          <div>Forecasted Remaining: {formatter.toPrice(totalForecasted)}</div>
        </div>

        {expand ? <List noBackground cols={cols} rows={rows} /> : null}
      </div>
    </div>
  )
}

function PlanPeriodList ({ props }) {
  const { clientId, clientRefId } = props
  const [init, setInit] = useState(true)
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [saving, setSaving] = useState(false)
  const [total, setTotal] = useState(0)

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  const listByPage = useCallback(({ page }) => {
    const _page = typeof page === 'number' && page > 0 ? page : 1
    setLoading(true)
    setPage(_page)
    clientPlanPaceService.listByPage(_page, defaultPageSize, { client_id: clientId })
      .then((response) => {
        if (validator.isObject(response)) {
          const { list, total } = getClientPlanList(response)

          if (Array.isArray(list)) {
            setList(list)
          }

          if (typeof total === 'number') {
            setTotal(total)
          }
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [clientId])

  const changePage = useCallback((page) => {
    listByPage({ page })
  }, [listByPage])

  const deleteClientPlan = useCallback((id, item) => {
    if (hasAccess(Permissions.PARTICIPANT.PP_PACE.DELETE)) {
      const {
        start_date: startDate, end_date: endDate, totalAllocated, totalForecasted
      } = item
      const canDelete = BigNumber(totalAllocated).isEqualTo(totalForecasted)
      const label = `${formatter.toShortDate(startDate)} - ${formatter.toShortDate(endDate)}`

      if (canDelete) {
        confirm({
          title: 'Are you sure you want to delete this plan period?',
          content: 'Press Ok to continue, Cancel to return',
          onOk: async () => {
            if (loading || saving) {
              return
            }

            setSaving(true)
            clientPlanPaceService.remove(id)
              .then((response) => {
                if (validator.isObject(response) && validator.isId(response.id)) {
                  notify.success('Deleted successfully', `Plan period "${label}" deleted successfully.`)
                  listByPage({ page: 1 })
                } else {
                  notify.error(
                    'Unable to delete successfully', `Unable to delete plan period "${label}" successfully. Please try again later.`
                  )
                }
              })
              .catch(() => {
                notify.error(
                  'Unable to delete successfully', `Unable to delete plan period "${label}" successfully. Please try again later.`
                )
              })
              .finally(() => {
                setSaving(false)
              })
          }
        })
      } else {
        info({
          title: 'Unable to delete successfully',
          content: 'You are not allowed to delete plan period that has invoice(s) booked under it'
        })
      }
    }
  }, [hasAccess, listByPage, loading, saving])

  const editClientPlan = useCallback((id) => {
    if (hasAccess([Permissions.PARTICIPANT.PP_PACE.READ, Permissions.PARTICIPANT.PP_PACE.UPDATE])) {
      if (window) {
        const url = `/participants/${clientRefId}/plan-period-pace/${id}`
        const newTab = window.open(url, '_blank', 'noopener noreferrer')

        if (newTab) {
          newTab.focus()
        }
      }
    }
  }, [hasAccess, clientRefId])

  const toggleExpandClientFunding = useCallback((id, planId) => {
    setList(list.map((item) => {
      const { id: _id, client_fundings: clientFundings } = item

      if (_id === planId) {
        item.client_fundings = clientFundings.map((clientFunding) => {
          const { id: _id, expand } = clientFunding

          if (_id === id) {
            clientFunding.expand = !expand
          }

          return clientFunding
        })
      }

      return item
    }))
  }, [list])

  const toggleExpandClientPlan = useCallback((id) => {
    setList(list.map((item) => {
      const { id: _id, expand } = item

      if (_id === id) {
        item.expand = !expand
      }

      return item
    }))
  }, [list])

  useEffect(() => {
    let mounted = true

    if (!hasAccess(Permissions.PARTICIPANT.PP_PACE.LIST)) {
      setInit(false)
      return
    }

    if (validator.isId(clientId)) {
      setLoading(true)
      clientPlanPaceService.listByPage(1, defaultPageSize, { client_id: clientId })
        .then((response) => {
          if (mounted && validator.isObject(response)) {
            const { list, total } = getClientPlanList(response)

            if (Array.isArray(list)) {
              setList(list)
            }

            if (typeof total === 'number') {
              setTotal(total)
            }
          }
        })
        .finally(() => {
          if (mounted) {
            setInit(false)
            setLoading(false)
          }
        })
    }

    return () => {
      mounted = false
    }
  }, [hasAccess, clientId])

  return (
    <Skeleton active loading={init}>
      <Spin spinning={loading}>
        <div className='ppp-list'>
          {list.map((clientPlan) => {
            const {
              id, plan_id: planId, start_date: startDate, end_date: endDate, role_start_date: roleStartDate,
              role_end_date: roleEndDate, active, client_fundings: clientFundings, client_plan_items: clientPlanItems,
              total_allocated: totalAllocated, total_remaining: totalRemaining, total_forecasted: totalForecasted,
              expand, highlight
            } = clientPlan
            const _clientPlanItems = Array.isArray(clientPlanItems) ? clientPlanItems : []
            const item = {
              id, planId, startDate, endDate, roleStartDate, roleEndDate, totalAllocated, totalRemaining, totalForecasted, expand
            }

            return (
              <div key={id} className={clsx('ppp-list-item', highlight ? 'highlight' : '')}>
                <PlanPeriodListBody
                  className={clsx('cp-box', active ? '' : 'inactive')} cols={getClientPlanColumns()} rows={_clientPlanItems}
                  hasAccess={hasAccess} item={item} onDeleteItem={deleteClientPlan} onEditItem={editClientPlan}
                  onToggleExpand={toggleExpandClientPlan}
                />

                {Array.isArray(clientFundings) && clientFundings.length > 0 ? clientFundings.map((clientFunding) => {
                  const {
                    id, plan_id: planId, start_date: startDate, end_date: endDate, client_funding_items: clientFundingItems,
                    total_allocated: totalAllocated, total_remaining: totalRemaining, total_forecasted: totalForecasted, expand
                  } = clientFunding
                  const _clientFundingItems = Array.isArray(clientFundingItems) ? clientFundingItems : []
                  const item = { id, planId, startDate, endDate, totalAllocated, totalRemaining, totalForecasted, expand }

                  return (
                    <div key={id}>
                      <PlanPeriodListBody
                        className='cf-box' cols={getClientFundingColumns()} rows={_clientFundingItems} item={item}
                        onToggleExpand={toggleExpandClientFunding}
                      />
                    </div>
                  )
                }) : (
                  <div className="no-funding-message" style={{ textAlign: 'center', fontStyle: 'italic', marginTop: '20px' }}>
                    No funding items available.
                  </div>
                )}
              </div>
            )
          })}
        </div>

        <Pager
          size={defaultPageSize}
          total={total}
          totalText={`Total ${total} plan periods`}
          current={page}
          onChange={changePage}
          style={{ marginTop: '15px' }}
        />
      </Spin>
    </Skeleton>
  )
}

export class PlanPeriodListPace extends Component {
  render () {
    const { form, props } = this.props
    return <PlanPeriodList props={{ form, ...props }} />
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return { ...state.PlanPeriodPace }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanPeriodListPace)
