import React, { Component, useCallback, useState, useEffect } from 'react'
import { Permissions } from '../../../constants'
import { statementPaceService } from '../../../services'
import { auth, exportFilePace, formatter, validator } from '../../../util'

// UI
import { List, Pager } from '../../../components'
import notify from '../../../components/Notification'

import Icon from 'antd/lib/icon'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const TabPane = Tabs.TabPane

const defaultPageSize = 20

function getStatementColumns (hasAccess, onGetStatement) {
  const getStatement = (item, isDownload, isSendEmail) => () => {
    if (typeof onGetStatement === 'function') {
      onGetStatement(item, isDownload, isSendEmail)
    }
  }

  return Object.freeze([
    {
      title: 'Period',
      width: 3,
      render: ({ name_full: name }) => name
    },
    {
      title: 'Funding Period',
      width: 4,
      render: ({ start_date: startDate, end_date: endDate }) => `${formatter.toShortDate(startDate)} - ${formatter.toShortDate(endDate)}`
    },
    {
      title: 'Opening Bal',
      width: 3,
      render: ({ opening_balance: openingBalance }) => formatter.toPrice(openingBalance)
    },
    {
      title: 'Monthly Spend',
      width: 3,
      render: ({ current_invoiced: currentInvoiced }) => formatter.toPrice(currentInvoiced)
    },
    {
      title: 'Closing Bal',
      width: 3,
      render: ({ closing_balance: closingBalance }) => formatter.toPrice(closingBalance)
    },
    {
      title: '',
      width: 2,
      render: (item) => {
        const { email, pm_statement_email: pmStatementEmail } = item
        const hasEmail = (!validator.isNullOrUndefined(email) && !validator.isEmptyString(email)) ||
          (!validator.isNullOrUndefined(pmStatementEmail) && !validator.isEmptyString(pmStatementEmail))

        return (
          <div className='button-box'>
            {hasAccess(Permissions.STATEMENT.INFO_PACE.DOWNLOAD)
              ? (
                <Tooltip mouseLeaveDelay={0} title='Download'>
                  <Icon type='download' onClick={getStatement(item, true, false)} />
                </Tooltip>
              )
              : null}

            {hasAccess(Permissions.STATEMENT.INFO_PACE.EMAIL)
              ? (
                <Tooltip mouseLeaveDelay={0} title={hasEmail ? 'Send Email' : 'No statement email recipient'}>
                  {hasEmail
                    ? <Icon type='mail' onClick={getStatement(item, false, true)} />
                    : <Icon type='mail' style={{ color: '#ccc' }} />}
                </Tooltip>
              )
              : null}
          </div>
        )
      }
    }
  ])
}

function StatementList ({ clientId, type }) {
  const [init, setInit] = useState(true)
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)

  const listByPage = useCallback(({ page }) => {
    const _page = typeof page === 'number' && page > 0 ? page : 1
    const data = { client_id: clientId, invoice_type: type }
    setLoading(true)
    setPage(_page)
    statementPaceService.listStatement(page, defaultPageSize, {}, {}, undefined, data)
      .then((response) => {
        if (validator.isObject(response)) {
          const { list, total } = response

          if (Array.isArray(list)) {
            setList(list)
          }

          if (typeof total === 'number') {
            setTotal(total)
          }
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [clientId, type])

  const changePage = useCallback((page) => {
    listByPage({ page })
  }, [listByPage])

  const getStatement = useCallback(async (item, isDownload = true, isSendEmail = false) => {
    const {
      client_id: clientId, cf_id: cfId, report_period_id: rpId, id_number: idNumber, name_full: periodNameFull, participant: clientName
    } = item || {}
    const filter = {
      id: rpId, booking_number: idNumber, budget_id: cfId, budget_client_id: clientId, type, is_download: isDownload,
      is_send_email: isSendEmail
    }
    const periodName = periodNameFull.split(/\s+/g).map((text, idx) => {
      if (idx === 0) {
        return text.substring(0, 3)
      }

      return text
    }).join('')
    const filename = `${clientName ? clientName.replace(/\s+/g, '') : clientName}-${periodName}`
    const r = await exportFilePace.fetchStatement(filter, filename)

    if (r && r.id && isSendEmail) {
      notify.success('Emailed Successfully', 'Statement emailed successfully.')
    } else if (isSendEmail) {
      notify.error('Unable to email', 'Statement is not emailed successfully. Please try again later.')
    }
  }, [type])

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  useEffect(() => {
    if (!hasAccess(Permissions.PARTICIPANT.STMTS_PACE.LIST)) {
      setInit(false)
      return
    }

    let mounted = true

    if (validator.isId(clientId)) {
      const data = { client_id: clientId, invoice_type: type }
      setLoading(true)
      statementPaceService.listStatement(1, defaultPageSize, {}, {}, undefined, data)
        .then((response) => {
          if (mounted && validator.isObject(response)) {
            const { list, total } = response

            if (Array.isArray(list)) {
              setList(list)
            }

            if (typeof total === 'number') {
              setTotal(total)
            }
          }
        })
        .finally(() => {
          if (mounted) {
            setInit(false)
            setLoading(false)
          }
        })
    }

    return () => {
      mounted = false
    }
  }, [hasAccess, clientId, type])

  return (
    <Skeleton active loading={init}>
      <div className='csp-statement-list'>
        <Spin spinning={loading}>
          <List cols={getStatementColumns(hasAccess, getStatement)} rows={list} />

          <Pager
            size={defaultPageSize}
            total={total}
            totalText={`Total ${total} statements`}
            current={page}
            onChange={changePage}
            style={{ marginTop: '15px' }}
          />
        </Spin>
      </div>
    </Skeleton>
  )
}

function ClientStatement (props) {
  const { clientId } = props || {}
  const [currentTab, setCurrentTab] = useState('1')

  const handleChangeTab = (key) => {
    setCurrentTab(key)
  }

  return (
    <div>
      <Tabs
        activeKey={currentTab}
        type="card"
        onChange={handleChangeTab}
      >
        <TabPane tab='PM Statements' key='1'>
          <StatementList clientId={clientId} type="pm" />
        </TabPane>

        <TabPane tab='Standard Booking Statements' key='2'>
          <StatementList clientId={clientId} type="std" />
        </TabPane>
      </Tabs>
    </div>
  )
}

export class ClientStatementPace extends Component {
  render () {
    return <ClientStatement {...this.props} />
  }
}

export default ClientStatementPace
