import React, { Component, useState, useCallback, useEffect } from 'react'
import debounce from 'lodash.debounce'

import { ExportType, InvoiceListType, StatementMenu, Permissions } from '../../../constants'
import { statementPaceService } from '../../../services'
import { auth, common, exportFilePace, formatter, validator } from '../../../util'

// UI
import { Button, ControlLabel, List, Page, Pager, SearchInput } from '../../../components'
import notify from '../../../components/Notification'

import Col from 'antd/lib/col'
import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const { confirm } = Modal

const pageSize = 20000

function getRowTextStyle (isClientActive, isBeyond) {
  let color = undefined
  if (isClientActive) {
    if (isBeyond) {
      color = '#FF3300'
    } else {
      color = undefined
    }
  } else {
    if (isBeyond) {
      color = '#FF330044'
    } else {
      color = '#ccc'
    }
  }

  return color
}

function getTitle (type) {
  let title

  if (type === InvoiceListType.INV_LIST_PM) {
    title = 'PM SB Statements'
  } else if (type === InvoiceListType.INV_LIST_STD) {
    title = 'Std SB Statements'
  } else {
    title = 'Statement Periods'
  }

  return title
}

function isBeyondMonth (d1, d2) {
  const d1s = formatter.toMoment(d1)
  const d2s = formatter.toMoment(d2)

  if (d2s.isAfter(d1s)) {
    return d2s.isSameOrAfter(d1s, 'month')
  } else {
    return false
  }
}

function getStatementColumns (hasAccess, onChangeCheckbox, onGetStatement) {
  const changeCheckbox = (idx) => () => {
    if (typeof onChangeCheckbox === 'function') {
      onChangeCheckbox(idx)
    }
  }

  const getStatement = (item, isDownload, isSendEmail) => () => {
    if (typeof onGetStatement === 'function') {
      onGetStatement(item, isDownload, isSendEmail)
    }
  }

  return Object.freeze([
    {
      title: 'Participant',
      width: 3,
      render: ({ client_ref_id: clientRefId, client_active: clientActive, date_to: dateTo, end_date: endDate, participant }) => {
        const isBeyond = isBeyondMonth(endDate, dateTo)

        return <div>
          <a
            style={{ color: getRowTextStyle(clientActive, isBeyond) }} href={`/participants/${clientRefId}/info`}
            rel='noopener noreferrer' target='_blank'
          >
            {participant}
          </a>
        </div>
      }
    },
    {
      title: 'Funding Period',
      width: 4,
      render: ({ client_active: clientActive, date_to: dateTo, start_date: startDate, end_date: endDate }) => {
        const isBeyond = isBeyondMonth(endDate, dateTo)

        return <div style={{ color: getRowTextStyle(clientActive, isBeyond) }}>
          {formatter.toShortDate(startDate)} - {formatter.toShortDate(endDate)}
        </div>
      }
    },
    {
      title: 'Opening Bal',
      width: 3,
      render: ({ client_active: clientActive, date_to: dateTo, end_date: endDate, opening_balance: openingBalance }) => {
        const isBeyond = isBeyondMonth(endDate, dateTo)

        return <div style={{ color: getRowTextStyle(clientActive, isBeyond) }}>{formatter.toPrice(openingBalance)}</div>
      }
    },
    {
      title: 'Total Paid Amount',
      width: 3,
      render: ({ client_active: clientActive, date_to: dateTo, end_date: endDate, current_invoiced: currentInvoiced }) => {
        const isBeyond = isBeyondMonth(endDate, dateTo)

        return <div style={{ color: getRowTextStyle(clientActive, isBeyond) }}>{formatter.toPrice(currentInvoiced)}</div>
      }
    },
    {
      title: 'Closing Bal',
      width: 3,
      render: ({ client_active: clientActive, date_to: dateTo, end_date: endDate, closing_balance: closingBalance }) => {
        const isBeyond = isBeyondMonth(endDate, dateTo)

        return <div style={{ color: getRowTextStyle(clientActive, isBeyond) }}>{formatter.toPrice(closingBalance)}</div>
      }
    },
    {
      title: 'Total Credit Used',
      width: 3,
      render: ({ client_active: clientActive, date_to: dateTo, end_date: endDate, current_credit_used: creditUsed }) => {
        const isBeyond = isBeyondMonth(endDate, dateTo)

        return <div style={{ color: getRowTextStyle(clientActive, isBeyond) }}>{formatter.toPrice(creditUsed)}</div>
      }
    },
    {
      title: '',
      width: 2,
      render: (item, idx) => {
        const { cf_id: cfId, email, pm_statement_email: pmStatementEmail, checked } = item
        const hasEmail = (!validator.isNullOrUndefined(email) && !validator.isEmptyString(email)) ||
          (!validator.isNullOrUndefined(pmStatementEmail) && !validator.isEmptyString(pmStatementEmail))
        const isChecked = typeof checked === 'boolean' ? checked : false

        return (
          <div className='button-box'>
            <input
              className='input-checkbox' name={`${cfId}-${idx}`} type='checkbox' checked={isChecked}
              onClick={changeCheckbox(idx)} onChange={changeCheckbox(idx)}
            />

            {hasAccess(Permissions.STATEMENT.INFO_PACE.DOWNLOAD)
              ? (
                <Tooltip mouseLeaveDelay={0} title='Download'>
                  <Icon type='download' onClick={getStatement(item, true, false)} />
                </Tooltip>
              )
              : null}

            {hasAccess(Permissions.STATEMENT.INFO_PACE.EMAIL)
              ? (
                <Tooltip mouseLeaveDelay={0} title={hasEmail ? 'Send Email' : 'No statement email recipient'}>
                  {hasEmail
                    ? <Icon type='mail' onClick={getStatement(item, false, true)} />
                    : <Icon type='mail' style={{ color: '#ccc' }} />}
                </Tooltip>
              )
              : null}
          </div>
        )
      }
    }
  ])
}

function StatementClient (props) {
  const { history, match } = props || {}
  const [detail, setDetail] = useState({})
  const [init, setInit] = useState(true)
  const [itemsIdsSelectErrMsg, setItemsIdsSelectErrMsg] = useState('')
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [searching, setSearching] = useState(false)
  const [selectAll, setSelectAll] = useState(false)
  const [total, setTotal] = useState(0)
  const { date, type = InvoiceListType.INV_LIST_PM } = common.getPath(match)
  const title = getTitle(type)
  const numChecked = list.filter(({ checked }) => checked).length
  const isSelect = numChecked > 0

  const getPeriodAba = useCallback(async (type) => {
    const id = detail.id
    const selectList = list.filter(({ checked }) => checked)

    if (id) {
      setLoading(true)
      // TODO - TODO - Fix this
      await exportFilePace.fetchFile(id, type, null, selectList)
      setTimeout(() => {
        setLoading(true)
      }, 3000)
    } else {
      notify.error('Unable to get ABA file', 'No Period Details available')
    }
  }, [detail, list])

  const getStatementMulti = useCallback(async (type, isSendEmail = false, isDownload = true) => {
    const selectList = list.filter(({ checked }) => checked)
    setLoading(true)
    const body = {
      id: detail.id,
      data: selectList,
      is_send_email: isSendEmail,
      is_download: isDownload
    }
    const r = await exportFilePace.fetchStatementMulti(body)
    if (isSendEmail) {
      if (r && r.id) {
        notify.success('Email Sent Successfully', 'Statements have been sent out by email successfully.')
      }

      setLoading(false)
    } else {
      setTimeout(() => {
        setLoading(false)
      }, 3000)
    }
  }, [detail, list])

  const listByPage = useCallback(async ({ page }) => {
    const _page = typeof page === 'number' && page > 0 ? page : 1
    setLoading(true)
    setPage(_page)
    const data = { type: type }
    const detail = await statementPaceService.getDate(date)

    if (validator.isObject(detail) && validator.isId(detail.id)) {
      statementPaceService.listStatementClientByPage(detail.id, _page, pageSize, {}, {}, undefined, data)
        .then((response) => {
          if (validator.isObject(response)) {
            const { list, total } = response

            if (Array.isArray(list)) {
              setList(list)
            }

            if (typeof total === 'number') {
              setTotal(total)
            }
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [type, date])

  const changeCheckbox = useCallback((idx) => {
    setList((list) => list.map((item, _idx) => {
      if (_idx === idx) {
        item.checked = !item.checked
      }

      return item
    }))
  }, [])

  const changeCheckboxAll = useCallback(({ target }) => {
    const { checked } = target || {}
    setSelectAll(!checked)
    setList((list) => list.map((item) => {
      item.checked = !checked
      return item
    }))
  }, [])

  const changePage = useCallback((page) => {
    listByPage({ page })
  }, [listByPage])

  const getStatement = useCallback(async (item, isDownload = true, isSendEmail = false) => {
    const {
      client_id: clientId, cf_id: cfId, report_period_id: rpId, id_number: idNumber, participant: clientName
    } = item || {}
    const { name: periodName } = detail || {}
    const filter = {
      id: rpId, booking_number: idNumber, budget_id: cfId, budget_client_id: clientId, type, is_download: isDownload,
      is_send_email: isSendEmail
    }
    const filename = `${clientName ? clientName.replace(/\s+/g, '') : clientName}-${periodName.replace(/\s+/g, '')}`
    const r = await exportFilePace.fetchStatement(filter, filename)

    if (r && r.id && isSendEmail) {
      notify.success('Emailed Successfully', 'Statement emailed successfully.')
    } else if (isSendEmail) {
      notify.error('Unable to email', 'Statement is not emailed successfully. Please try again later.')
    }
  }, [detail, type])

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  const preStatement = useCallback((type, isSendEmail, isDownload) => () => {
    setItemsIdsSelectErrMsg(`(${numChecked} item${numChecked === 1 ? ' is ' : 's are'} selected)`)

    const title = isSendEmail && isDownload
      ? `Are you sure to export and email selected items?`
      : isSendEmail
        ? `Are you sure to email selected items?`
        : `Are you sure to export selected items?`

    if (numChecked > 0) {
      const isEmailRecipientMissing = list.find(
        ({ email, pm_statement_email: pmStatementEmail, checked }) => checked && (
          validator.isNullOrUndefined(email) || validator.isEmptyString(email) ||
          validator.isNullOrUndefined(pmStatementEmail) || validator.isEmptyString(pmStatementEmail)
        )
      )

      const content = isSendEmail && isEmailRecipientMissing
        ? <div>
          <b style={{ color: 'red' }}>Some participants do not have valid email recipient.</b><br />
          Press OK to continue, Cancel to return
        </div>
        : <div>Press OK to continue, Cancel to return.</div>

      confirm({
        title: <div>{title}<br />{itemsIdsSelectErrMsg}</div>,
        content: content,
        onOk () {
          type === ExportType.TYPE_SB_PRODA || type === ExportType.TYPE_SB_PRODA_V2
            ? getPeriodAba(type)
            : getStatementMulti(type, isSendEmail, isDownload)
        }
      })
    } else {
      setItemsIdsSelectErrMsg('Item is not selected.')
    }
  }, [getPeriodAba, getStatementMulti, itemsIdsSelectErrMsg, list, numChecked])

  const searchName = useCallback(() => {
    const onSearchName = async (value) => {
      setLoading(true)
      setPage(1)
      setSearching(true)
      const data = { type: type }
      try {
        const detail = await statementPaceService.getDate(date)

        if (validator.isObject(detail) && validator.isId(detail.id)) {
          const response = await statementPaceService.listStatementClientByPage(detail.id, 1, pageSize, {}, {}, value, data)

          if (validator.isObject(response)) {
            const { list, total } = response

            if (Array.isArray(list)) {
              setList(list)
            }

            if (typeof total === 'number') {
              setTotal(total)
            }
          }
        }
      } finally {
        setLoading(false)
        setSearching(false)
      }
    }
    return debounce(onSearchName, 500)
  }, [date, type])

  useEffect(() => {
    if (!hasAccess(Permissions.STATEMENT.INFO_PACE.READ)) {
      setInit(false)
      return
    }

    let mounted = true
    setLoading(true)
    statementPaceService.getDate(date)
      .then((detail) => {
        if (mounted && validator.isObject(detail) && validator.isId(detail.id)) {
          const data = { type: type }
          setDetail(detail)
          statementPaceService.listStatementClientByPage(detail.id, page, pageSize, {}, {}, undefined, data)
            .then((response) => {
              if (mounted && validator.isObject(response)) {
                const { list, total } = response

                if (Array.isArray(list)) {
                  setList(list)
                }

                if (typeof total === 'number') {
                  setTotal(total)
                }
              }
            })
            .finally(() => {
              if (mounted) {
                setInit(false)
                setLoading(false)
              }
            })
        }
      })
      .catch(() => {
        if (mounted) {
          setInit(false)
          setLoading(false)
        }
      })

    return () => {
      mounted = false
    }
  }, [hasAccess, date, page, type])

  return (
    <Page.Body>
      <Page.Left>
        <Page.Menu title='Home' menu={StatementMenu} />
      </Page.Left>
      <Page.Content full>
        <Page.Header title={title} total={detail.name || ''} >
          <div className='btn' onClick={history.goBack}>Back</div>
        </Page.Header>

        <div className='action-buttons-sc'>
          {isSelect && type === InvoiceListType.INV_LIST_STD ? <Button style={{ backgroundColor: '#34ebb4' }} key='submitaba' feedback={loading} onClick={preStatement(ExportType.TYPE_SB_PRODA_V2)}>Download Pymt Req</Button> : null}
          {isSelect ? <Button style={{ backgroundColor: '#3d34eb' }} key='submit1' feedback={loading} onClick={preStatement(ExportType.TYPE_STMT, false, true)} >Download Selected</Button> : null}
          {isSelect ? <Button style={{ backgroundColor: '#ebc034' }} key='submit2' feedback={loading} onClick={preStatement(ExportType.TYPE_STMT, true, false)}>Email Selected</Button> : null}
          {isSelect ? <Button key='submit3' feedback={loading} onClick={preStatement(ExportType.TYPE_STMT, true, true)}>Download & Email Selected</Button> : null}
        </div>

        <Page.Filter>
          <Row gutter={8}>
            <Col lg={9}>
              <ControlLabel>Participant Name, Booking Number</ControlLabel>

              <SearchInput placeholder='Search statement' isSearching={searching} onChange={searchName()} />
            </Col>

            <Col lg={10} />

            <Col lg={5}>
              {list && validator.isNotEmptyArray(list) && !loading
                ? (
                  <div className='inv-sec-row-left'>
                    <div style={{ marginRight: '10px', fontSize: '11px', fontWeight: '400', color: '#333' }}>
                      Select / Deselect All ({numChecked} item{numChecked === 1 ? '' : 's'} selected)
                    </div>

                    <input
                      className='input-checkbox' name='check' type='checkbox' checked={selectAll} onClick={changeCheckboxAll}
                      onChange={changeCheckboxAll}
                    />
                  </div>
                )
                : null}
            </Col>
          </Row>
        </Page.Filter>

        <Skeleton active loading={init}>
          <div className='sp-statement-list'>
            <Spin spinning={loading}>
              <List cols={getStatementColumns(hasAccess, changeCheckbox, getStatement)} rows={list} />
            </Spin>

            <Pager
              size={pageSize}
              total={total}
              totalText={`Total ${total} statements`}
              current={page}
              onChange={changePage}
              style={{ marginTop: '15px' }}
            />
          </div>
        </Skeleton>
      </Page.Content>
    </Page.Body>
  )
}

export class StatementListClient extends Component {
  render () {
    return <StatementClient {...this.props} />
  }
}

export default StatementListClient